// Filters
export function camelize(text) {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    return text.substr(0, 1).toLowerCase() + text.substr(1);
}

export function capitalize(str) {
    if (str) {
        const lower = str.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
    }
}

export function firstLetter(word) {
    if (word) {
        return word.charAt(0);
    }
}

export function formatPhoneNumber(phoneNum) {
    if (phoneNum.substring(3, 7) == '1300') {
        // 1300 number
        return `${phoneNum.substring(3, 7)} ${phoneNum.substring(7, 10)} ${phoneNum.substring(10, 13)}`;
    } else if (phoneNum.substring(3, 4) == '4') {
        // mobile
        return `0${phoneNum.substring(3, 6)} ${phoneNum.substring(6, 9)} ${phoneNum.substring(9, 12)}`;
    } else {
        // landline number
        return `0${phoneNum.substring(3, 4)} ${phoneNum.substring(4, 8)} ${phoneNum.substring(8, 13)}`;
    }
}

export default {
    firstLetter,
    capitalize,
    formatPhoneNumber,
};
