<template>
    <div class="flex flex-col gap-4">
        <div class="flex items-center justify-between">
            <span class="text-lg font-medium">How Muval ensures quality</span>
            <button
                type="button"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                @click="closeTrustDialog()"
            >
                <span class="sr-only">Close panel</span>
                <XMarkIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                />
            </button>
        </div>
        <span class="text-xs text-muval-gray-1">
            All service providers are pre-screened through a rigorous qualification process and are monitored to
            maintain industry best practices.
        </span>

        <CommonCarousel :cardWidth="200">
            <div class="flex w-full gap-2 md:grid md:grid-cols-12">
                <div
                    v-for="(item, idx) in items"
                    :key="idx"
                    class="flex h-32 w-[200px] flex-none snap-center flex-col justify-center gap-y-2 rounded-lg border border-[#EFEFEF] p-2 sm:col-span-3 md:col-span-3 md:w-auto md:gap-y-4 md:p-3 md:pb-4"
                >
                    <!-- Icon -->
                    <div class="flex h-8 w-8 items-center justify-center rounded-md bg-[#F7F7F7] p-1.5">
                        <SvgIcon
                            class="text-black"
                            :name="item.icon"
                        />
                    </div>

                    <div class="flex flex-col gap-1">
                        <span class="text-xs font-medium text-[#9B9B9B]">{{ item.title }}</span>
                        <span class="text-xs font-medium text-muval-gray-1">{{ item.description }}</span>
                    </div>
                </div>

                <div
                    class="col-span-6 flex h-32 min-w-[200px] snap-center flex-col justify-between rounded-lg bg-brand/5 p-3 pb-4 sm:col-span-3 md:col-span-3 md:min-w-0"
                >
                    <!-- Title -->
                    <span class="text-sm text-muval-gray-1">Want to learn more about our selection process?</span>
                    <a
                        href="tel:1300168825"
                        class="cursor-pointer text-sm text-brand hover:underline"
                    >
                        Contact us
                    </a>
                </div>
            </div>
        </CommonCarousel>
    </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { closeTrustDialog } from '@/composables/dialog';

const items = [
    {
        icon: 'system-radar',
        title: 'Step 1',
        description: 'Initial application and pre-screening',
    },
    {
        icon: 'system-monitoring',
        title: 'Step 2',
        description: 'Verbal qualification interview',
    },
    {
        icon: 'system-cloud-done',
        title: 'Step 3',
        description: 'Background credit check',
    },
    {
        icon: 'system-approved',
        title: 'Step 4',
        description: 'Insurance validity and currency check',
    },
    {
        icon: 'system-verified',
        title: 'Step 5',
        description: 'Industry standards compliance check',
    },
    {
        icon: 'system-credit-score',
        title: 'Step 6',
        description: 'Online vendor reputation audit',
    },
    {
        icon: 'system-hq',
        title: 'Step 7',
        description: 'Ongoing monitor & audit of practices',
    },
];
</script>
