<template>
    <div
        v-if="item.id"
        class="group flex w-full cursor-pointer justify-between py-2"
        @click.prevent="addQty"
    >
        <div class="my-auto flex w-full select-none items-center gap-4 text-base">
            <div>{{ item.name }}</div>
            <div
                v-if="inventory.searchTerm"
                class="my-auto rounded-muval-1 bg-muval-gray-5 px-2 py-0.5 text-xs font-medium"
            >
                {{ inventory.categories.find((category) => category.id == item.inventory_category_id)?.name }}
            </div>
        </div>
        <div class="flex justify-between gap-2">
            <button
                v-show="field?.value?.qty > 0"
                type="button"
                class="my-auto h-10 w-10 rounded-full bg-[#E5E7EB] hover:bg-[#D1D5DB] md:h-6 md:w-6"
                title="Reduce item quantity"
                @click.stop.prevent="removeQty"
            >
                <SvgIcon
                    class="m-auto h-3 w-3 cursor-pointer"
                    name="system-minus"
                />
            </button>

            <input
                v-show="field?.value?.qty > 0"
                type="numeric"
                class="mx-auto my-auto h-10 w-10 rounded-full border px-2 text-center outline-none focus:border-black md:h-6 md:w-10"
                :class="[field?.value?.qty > 99 ? 'text-xxs' : 'text-sm']"
                :value="field?.value?.qty"
                @focus="isFocused = true"
                @input="handleInput($event)"
                @click.stop
                @blur="handleBlur($event)"
            />

            <button
                type="button"
                class="my-auto h-10 w-10 rounded-full bg-[#E5E7EB] group-hover:bg-[#D1D5DB] md:h-6 md:w-6"
                title="Increase item quantity"
                @click.stop.prevent="addQty"
            >
                <SvgIcon
                    class="m-auto h-3 w-3"
                    name="system-plus"
                />
            </button>
        </div>
    </div>
</template>

<script setup>
import { useFieldArray } from 'vee-validate';
import { useInventoryStore } from '@/store/inventory';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const inventory = useInventoryStore();

const isFocused = ref(false);

const { fields, update, remove, push } = useFieldArray('move.inventory');

const indexOfField = computed(() => {
    return fields.value.findIndex((field) => field?.value?.inventory_option_id === props.item.id);
});

const field = computed(() => {
    return fields.value[indexOfField.value];
});

function handleInput(event) {
    // updateQty no matter what but if 0 then dont as it would remove the input
    if (event.target.value == 0) {
        return;
    }
    updateQty(event);
}

function handleBlur(event) {
    updateQty(event);
}

function updateQty(event) {
    const newValue = parseInt(event.target.value);

    if (newValue > 250) {
        return;
    }

    if (!newValue) {
        remove(indexOfField.value);
        return;
    }

    update(indexOfField.value, {
        ...field.value.value,
        qty: newValue,
    });
    isFocused.value = false;
}

function addQty() {
    if (field?.value?.value?.qty >= 250) {
        return;
    }

    if (!field?.value?.value?.qty) {
        push({
            inventory_option_id: props.item.id,
            qty: 1,
        });
        return;
    }

    const newValue = field.value.value.qty + 1;
    update(indexOfField.value, {
        ...field.value.value,
        qty: newValue,
    });
}

function removeQty() {
    if (field?.value?.value?.qty == 1) {
        remove(indexOfField.value);
        return;
    }

    const newValue = field.value.value.qty - 1;
    update(indexOfField.value, {
        ...field.value.value,
        qty: newValue,
    });
}
</script>
