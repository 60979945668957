<template>
    <MuvalButton
        v-if="slaask.show"
        :icon="chatOpen ? 'system-close' : 'system-chat'"
        alternate
        type="button"
        quaternary
        class="!text-muval-gray-1"
        @click="slaask.toggleChat"
    >
        Chat
    </MuvalButton>
</template>

<script setup>
import { useAppStore } from '@/store';

const { slaask } = useAppStore();
</script>
