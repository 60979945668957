<template>
    <div class="flex flex-col gap-4 bg-opacity-5">
        <div class="-mt-1.5 flex items-center justify-between">
            <span class="text-lg font-medium text-white">Moving Masterclass</span>
            <button
                type="button"
                class="rounded-full text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                @click="closeVideoDialog()"
            >
                <span class="sr-only">Close panel</span>
                <XMarkIcon
                    class="h-6 w-6"
                    aria-hidden="true"
                />
            </button>
        </div>
        <div class="-mx-5 -mb-6">
            <div class="aspect-h-9 aspect-w-16 overflow-clip rounded-b-lg">
                <iframe
                    width="100"
                    height="100"
                    :src="source"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { closeVideoDialog } from '@/composables/dialog';
import { useSessionStore } from '@/store';

const session = useSessionStore();
const source = computed(() => `https://www.youtube.com/embed/${session.videoEmbed}?autoplay=1`);
</script>
