<template>
    <TransitionRoot
        appear
        as="template"
        :show="open"
    >
        <Dialog
            as="div"
            :initial-focus="defaultFocus"
            static
            class="fixed -bottom-20 left-0 right-0 z-50 mx-auto"
            :open="open"
            @close="closeOnOutside ? closeHandler() : null"
        >
            <div
                class="mx-auto flex min-h-[100svh] max-w-5xl items-end justify-center pb-20 pt-4 text-center sm:block md:px-8"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-300 opacity-40 transition-opacity" />
                </TransitionChild>
                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    v-if="!top"
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="sm:max-w-screen inline-block max-h-[90vh] transform overflow-auto bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle"
                        :class="[
                            {
                                'rounded-t-lg sm:rounded-lg': rounded,
                                'max-w-4xl': !maxWidth,
                            },
                            modalSize,
                        ]"
                    >
                        <div class="h-full w-full md:overflow-y-auto">
                            <div class="relative">
                                <div class="sticky top-0 z-10 flex h-16 bg-white px-6 pb-5 pt-6">
                                    <div v-show="hasHeaderSlot">
                                        <slot name="header"></slot>
                                    </div>
                                    <DialogTitle
                                        v-if="title"
                                        as="div"
                                        class="my-auto text-sm font-medium md:text-lg md:leading-5"
                                    >
                                        {{ title }}
                                    </DialogTitle>
                                    <button
                                        id="closeButton"
                                        type="button"
                                        class="-mx-2 -my-2 my-auto ml-auto flex h-8 w-8 items-center justify-center rounded-full bg-white hover:bg-gray-100 focus:outline-none"
                                        @click="closeHandler"
                                    >
                                        <XMarkIcon class="h-5 w-5 text-gray-900"></XMarkIcon>
                                    </button>
                                </div>
                                <div class="px-6 py-6 !pt-0 font-body md:py-6">
                                    <slot />
                                </div>
                            </div>
                        </div>

                        <div
                            v-show="hasFooterSlot"
                            class="border-t bg-gray-50 px-4 py-3 sm:px-6"
                        >
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    title: {
        type: String,
        required: false,
        default: '',
    },
    rounded: {
        type: Boolean,
        required: false,
        default: true,
    },
    closeButton: {
        type: Boolean,
        required: false,
        default: true,
    },
    closeOnOutside: {
        type: Boolean,
        required: false,
        default: true,
    },
    focusRef: {
        type: [Object, null],
        required: false,
        default: null,
    },
    top: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        required: false,
        default: 'large',
        validator: (value) => ['small', 'medium', 'payment', 'large'].includes(value),
    },
    closeViaEvent: {
        type: Boolean,
        required: false,
        default: false,
    },
    provider: {
        type: [Object, null],
        required: false,
        validator: (value) => {
            return (
                typeof value === 'object' &&
                value !== null &&
                typeof value.isVisible === 'boolean' &&
                typeof value.text === 'string' &&
                typeof value.reject === 'function' &&
                typeof value.resolve === 'function'
            );
        },
        default: null,
    },
});

const emit = defineEmits(['update:modelValue', 'close', 'cancel', 'confirm']);

const slots = useSlots();

const defaultFocus = ref(null);

async function closeHandler() {
    if (props.provider) {
        await props.provider.reject();
        open.value = false;
    } else if (props.closeViaEvent) {
        emit('close');
    } else {
        open.value = false;
    }
}

const hasHeaderSlot = computed(() => {
    return !!slots.header;
});

const hasFooterSlot = computed(() => {
    return !!slots.footer;
});

const modalSize = computed(() => {
    switch (props.size) {
        case 'small':
            return 'sm:w-1/3 w-full';
        case 'medium':
            return 'sm:w-2/3 w-full';
        case 'payment':
            return 'sm:w-[525px] w-full';
        default:
            return 'sm:w-full w-full';
    }
});

const open = computed({
    get: () => {
        if (props.provider) {
            return props.provider.isVisible.value;
        }
        return props.modelValue;
    },
    set: (value) => {
        if (props.provider) {
            if (value) {
                props.provider.resolve();
            } else {
                props.provider.reject();
            }
        } else {
            emit('update:modelValue', value);
        }
    },
});
</script>
