import { useSessionStore, useResultsStore } from '@/store';
import { START_LOCATION } from 'vue-router';

/*
 * At the point the router enters result routes,
 * the session store and any async data should have been fetched.
 *
 * So beforeEnter guards here can and should be synchronous.
 */

export default [
    {
        path: '/results/loading',
        name: 'Loading',
        meta: {
            title: 'Loading matches',
        },
        component: () => import('@/pages/Loading.vue'),
        beforeEnter: () => {
            const session = useSessionStore();

            if (session.hasConfirmed) {
                return '/results/confirmed';
            }

            return;
        },
    },
    {
        path: '/results/recommended',
        name: 'Recommended',
        meta: {
            title: 'Results - Recommended',
        },
        component: () => import('@/pages/Recommended.vue'),
        beforeEnter: async (to, from) => {
            const session = useSessionStore();

            if (session.hasConfirmed) {
                return '/results/confirmed';
            }

            // always wait for prefetch so local to interstate switch does not cause issues
            // todo - really the cache should clear when the type switches but this is a quick fix as I found clearing the cache was unreliable
            const results = useResultsStore();
            results.prefetch();

            // takes precedence over other re-routes
            if (from == START_LOCATION && session.hasSelectedMatch) {
                return '/results/review';
            }

            if (!session.hasRecommendedMatches) {
                return '/results/organic';
            }
        },
    },
    {
        path: '/results/organic',
        name: 'Organic',
        meta: {
            title: 'Results - Organic',
        },
        component: () => import('@/pages/Organic.vue'),
        beforeEnter: async (to, from) => {
            const session = useSessionStore();

            if (session.hasConfirmed) {
                return '/results/confirmed';
            }

            // always wait for prefetch so local to interstate switch does not cause issues
            // todo - really the cache should clear when the type switches but this is a quick fix as I found clearing the cache was unreliable
            const results = useResultsStore();
            results.prefetch();

            // takes precedence over other re-routes
            if (from == START_LOCATION && session.hasSelectedMatch) {
                return '/results/review';
            }

            // if initial load and had recommended matches, go to recommended
            if (from == START_LOCATION && session.hasRecommendedMatches) {
                return '/results/recommended';
            }
        },
    },
    {
        path: '/results/review',
        name: 'Review',
        meta: {
            title: 'Review',
        },
        component: () => import('@/pages/Review.vue'),
        beforeEnter: () => {
            const session = useSessionStore();

            if (session.hasConfirmed) {
                return '/results/confirmed';
            } else {
                if (session.hasSelectedMatch) {
                    return;
                } else {
                    return '/results/organic';
                }
            }
        },
    },
    {
        path: '/results/confirmed',
        name: 'Confirmed',
        meta: {
            title: 'Confirmed',
        },
        component: () => import('@/pages/Confirmed.vue'),
        beforeEnter: () => {
            const session = useSessionStore();

            if (session.hasConfirmed) {
                return;
            } else {
                if (session.hasSelectedMatch) {
                    return '/results/review';
                } else {
                    return '/results/recommended';
                }
            }
        },
    },
];
