// import { useSessionStore, useResultsStore, useFeedbackStore } from '@/store';
// import { START_LOCATION } from 'vue-router';

/*
 * At the point the router enters result routes,
 * the session store and any async data should have been fetched.
 *
 * So beforeEnter guards here can and should be synchronous.
 */

import { useFeedbackStore } from '@/store';

export default [
    {
        path: '/feedback/removalist',
        name: 'FeedbackRemovalist',
        meta: {
            title: 'Feedback - Removalist',
        },
        component: () => import('@/pages/feedback/FeedbackRemovalist.vue'),
        beforeEnter: () => {
            const feedbackStore = useFeedbackStore();

            if (feedbackStore.pageNum < 1) {
                return '/feedback';
            }
        },
    },
    {
        path: '/feedback/removalist-additional',
        name: 'FeedbackRemovalistAdditional',
        meta: {
            title: 'Feedback - Removalist additional',
        },
        component: () => import('@/pages/feedback/FeedbackRemovalistAdditional.vue'),
        beforeEnter: () => {
            const feedbackStore = useFeedbackStore();

            if (feedbackStore.pageNum < 2) {
                return '/feedback';
            }
        },
    },
];
