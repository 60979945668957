import { hasCookie, deleteCookie } from './utils';

const routes = {
    csrf: '/customers/book/csrf',
    login: '/customers/book/token',
};

async function fetchCSRFToken() {
    return await window.axios.get(routes.csrf);
}

async function login(credentials) {
    await this.fetchCSRFToken();
    return await window.axios.post(routes.login, credentials);
}

function logout() {
    return window.axios.post(routes.logout).finally(() => {
        deleteCookie('XSRF-TOKEN');
        return true;
    });
}

function hasXSRFToken() {
    return hasCookie('XSRF-TOKEN');
}

export default {
    fetchCSRFToken,
    login,
    logout,
    hasXSRFToken,
};
