<template>
    <div
        class="radio-small-content"
        :class="isSelected ? 'radio-checked' : 'radio-unchecked'"
    >
        <span class="radio-small-label">{{ tag.item }}</span>
    </div>
</template>

<script setup>
const props = defineProps({
    tag: {
        type: Object,
        required: true,
    },
    selectedTags: {
        type: Array,
        required: true,
    },
    error: {
        type: Boolean,
        default: false,
    },
});

const isSelected = computed(() => {
    if (props.selectedTags?.length) {
        return props.selectedTags.some((field) => {
            return field?.value?.id === props.tag.id;
        });
    } else {
        return false;
    }
});
</script>
