<template>
    <div class="flex flex-col">
        <span class="text-xl font-medium">Select a booking option</span>
        <div class="relative h-full w-full pt-4">
            <!-- Mask -->
            <TransitionGroup
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-x-90 opacity-0"
                enter-to-class="transform scale-x-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-x-100 opacity-100"
                leave-to-class="transform scale-x-90 opacity-0"
            >
                <div
                    v-show="!arrivedState.right"
                    class="absolute right-0 hidden h-full w-12 bg-gradient-to-l from-white to-transparent md:block"
                ></div>
                <div
                    v-show="!arrivedState.left"
                    class="absolute left-0 hidden h-full w-12 bg-gradient-to-r from-white to-transparent md:block"
                ></div>
            </TransitionGroup>

            <Transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-y-90 opacity-0"
                enter-to-class="transform scale-y-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-y-100 opacity-100"
                leave-to-class="transform scale-y-90 opacity-0"
            >
                <button
                    v-if="!arrivedState.left"
                    class="absolute bottom-1.5 left-4 flex h-9 w-9 items-center justify-center rounded-full border bg-white md:top-1/2 md:-translate-y-1/2"
                    @click="scrollLeft"
                >
                    <SvgIcon
                        class="h-4 w-4"
                        name="system-chevron-left"
                    />
                </button>
            </Transition>
            <Transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-y-90 opacity-0"
                enter-to-class="transform scale-y-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-y-100 opacity-100"
                leave-to-class="transform scale-y-90 opacity-0"
            >
                <button
                    v-if="!arrivedState.right"
                    class="absolute bottom-1.5 right-4 flex h-9 w-9 items-center justify-center rounded-full border bg-white md:top-1/2 md:-translate-y-1/2"
                    @click="scrollRight"
                >
                    <SvgIcon
                        class="h-4 w-4"
                        name="system-chevron-right"
                    />
                </button>
            </Transition>

            <div
                ref="scrollContainer"
                class="flex snap-x gap-4 overflow-x-auto pb-12 md:pb-4"
            >
                <div
                    v-for="(option, index) in options"
                    :key="index"
                    class="flex min-w-[260px] snap-center flex-col gap-4 rounded-lg border p-4 md:min-w-[300px]"
                >
                    <ul class="flex flex-col gap-3 text-xs font-medium">
                        <li class="flex justify-between">
                            <span class="font-medium leading-none text-[#9B9B9B]">Pickup</span>
                            <span class="font-medium leading-none">
                                <span>{{ option.dates.pickup.formatted }}</span>
                            </span>
                        </li>
                        <li class="flex justify-between">
                            <span class="font-medium text-[#9B9B9B]">Delivery</span>
                            <span class="font-medium leading-none">
                                <span>{{ option.dates.delivery.formatted }}</span>
                            </span>
                        </li>
                    </ul>

                    <hr />

                    <!-- Price breakdown -->
                    <ul class="flex flex-col gap-3">
                        <li
                            v-for="(item, bIndex) in option.breakdown"
                            :key="bIndex"
                            class="flex justify-between text-xs font-medium"
                        >
                            <span
                                :class="{
                                    'pl-2': item.type === BreakdownType.ACCESS,
                                }"
                                class="leading-none text-[#9B9B9B]"
                            >
                                {{ item.label }}
                            </span>
                            <span class="leading-none">{{ item.value }}</span>
                        </li>
                    </ul>

                    <!-- Price Totals -->
                    <div class="-mx-4 -mb-4 mt-auto flex items-center justify-between bg-[#F8F8F8] p-4">
                        <div class="flex flex-col items-center gap-1">
                            <span class="text-xl font-medium leading-none">{{ option.price.formatted }}</span>
                            <span
                                v-if="option.min_price"
                                class="text-[10px] font-normal leading-none text-[#9B9B9B]"
                            >
                                Min price {{ option.min_price.formatted }}
                            </span>
                        </div>

                        <MuvalButton
                            v-if="!readonly"
                            alternate
                            :loading="option.selecting"
                            @click="results.selectMatch(option)"
                        >
                            Select
                        </MuvalButton>
                    </div>
                </div>
            </div>
        </div>

        <hr class="pb-6" />

        <div class="flex flex-col">
            <span class="text-sm font-medium">Need to speak with a move specialist?</span>
            <span class="text-xs font-normal">
                Contact our friendly customer service team on
                <a
                    class="underline"
                    href="tel:1300 168 825"
                >
                    1300 168 825
                </a>
                <span v-if="slaask.show">
                    or chat with us
                    <a
                        class="cursor-pointer underline"
                        @click="slaask.toggleChat"
                    >
                        online
                    </a>
                    to help you choose the option best suited to your move.
                </span>
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type MoveMatchOption, BreakdownType } from '@/types';
import { useAppStore, useResultsStore } from '@/store';
import { useScroll } from '@vueuse/core';

interface Props {
    options: MoveMatchOption[];
    readonly?: Boolean;
}

defineProps<Props>();

const results = useResultsStore();

const { slaask } = useAppStore();

const scrollContainer = ref<HTMLElement>();

const { arrivedState } = useScroll(scrollContainer);

const cardWidth = 280;

const scrollRight = () => {
    if (!scrollContainer.value) return;
    const maxScrollLeft = scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
    if (scrollContainer.value.scrollLeft < maxScrollLeft) {
        scrollContainer.value.scrollBy({ left: cardWidth, behavior: 'smooth' });
    }
};

const scrollLeft = () => {
    if (!scrollContainer.value) return;
    if (scrollContainer.value.scrollLeft > 0) {
        scrollContainer.value.scrollBy({ left: -cardWidth, behavior: 'smooth' });
    }
};
</script>
