<template>
    <CheckoutForm
        :order-item-id="getOrderItemId"
        :has-schedule="false"
        :confirm-text="confirmText"
        :payment-amount="getPaymentAmount"
        :payment-label="getConfirmServiceList"
        @payment:success="closeInsurancePaymentDialog"
    >
        <template #info>
            <template v-if="hasPendingDepositWithInsurance">
                Your payment information is needed to confirm your booking request. You will not be charged until your
                removalist confirms your booking with you.
            </template>
            <template v-else>
                To confirm the new items you want {{ session.getInsurancePolicyVerbPast }} please pay the amount owing
                for {{ session.getInsurancePolicyBrand }} of
                <span class="font-medium">{{ getPaymentAmount }}</span>
                <span v-if="session.cardFeesEnabled"> plus a card processing fee</span>.
            </template>
        </template>
    </CheckoutForm>
</template>

<script setup>
import { closeInsurancePaymentDialog } from '@/composables/dialog';
import { useSessionStore } from '@/store';

const session = useSessionStore();

const isImmediate = computed(() => {
    if (
        session.getPrimaryProduct.status === 'COMPLETED' ||
        session.getPrimaryProduct.status === 'READY' ||
        session.getPrimaryProduct.status === 'ACCEPTED'
    ) {
        // if the primary product has progressed beyond acceptance, payment is immediate
        return true;
    } else {
        // otherwise it can be added to the existing primary product payment
        return false;
    }
});

const getOrderItemId = computed(() => {
    if (isImmediate.value) {
        // limit payment to just insurance if move has been confirmed
        return session.getInsuranceItem?.id;
    } else {
        // otherwise leave it open to include other items
        return null;
    }
});

const hasPendingDepositWithInsurance = computed(() => {
    if (session.getInsuranceItemProduct?.amount_owing?.amount && !isImmediate.value) {
        return true;
    } else {
        return false;
    }
});

const getPaymentAmount = computed(() => {
    if (hasPendingDepositWithInsurance.value) {
        return session.getItemsDepositAmount;
    } else {
        return session.getInsuranceItemProduct?.amount_owing?.formatted;
    }
});

const confirmText = computed(() => {
    if (isImmediate.value) {
        return `Pay ${session.getInsurancePolicyBrand}`;
    } else {
        return 'Yes confirm';
    }
});

const getConfirmServiceList = computed(() => {
    if (!hasPendingDepositWithInsurance.value) {
        return session.getInsurancePolicyBrand;
    }

    let str = [];

    const confirmableItems = session.order.items.filter(
        (item) => item?.status !== 'CONFIRMED' || item?.service_status === 'Pending',
    );

    for (const item of confirmableItems) {
        if (item.productable_type === 'move') {
            const isDeposit =
                item.product.selectedMatch.pricing_type === 'VARIABLE' || !item.product.selectedMatch.within_week;

            if (confirmableItems.length > 1) {
                str.push(`${item.service_label} ${isDeposit ? 'Deposit' : 'Total'}`);
            } else {
                str.push(isDeposit ? 'Deposit' : 'Total');
            }
        } else {
            str.push(item.service_label);
        }
    }

    return str.join(' + ');
});
</script>
