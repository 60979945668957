import { until } from '@vueuse/core';
import { useSessionStore } from '@/store';

export const isPickupDialogOpen = ref(false);
export const confirmDialogChoice = ref();
export const confirmDialogLabel = ref();
export const errorDialogData = ref();
export const isSummaryDialogOpen = ref(false);
export const isDeliveryDialogOpen = ref(false);
export const isPickupAddressDialogOpen = ref(false);
export const isDeliveryAddressDialogOpen = ref(false);
export const isDateDialogOpen = ref(false);
export const isSpaceDialogOpen = ref(false);
export const isCustomItemDialogOpen = ref(false);
export const isRequiredSpaceDialogOpen = ref(false);
export const isInlineCustomItemDialogOpen = ref(false);
export const isInlineRequiredSpaceDialogOpen = ref(false);
export const isUtilitiesDialogOpen = ref(false);
export const isInsuranceDialogOpen = ref(false);
export const isInsurancePaymentDialogOpen = ref(false);
export const isCompanyInfoDialogOpen = ref(false);
export const isTrustDialogOpen = ref(false);
export const isReadonlyMatchListDialogOpen = ref(false);
export const isReadonlyCompanyInfoDialogOpen = ref(false);
export const isVideoDialogOpen = ref(false);
export const isTaxiboxDialogOpen = ref(false);

export const isConfirmDialogOpen = ref(false);
export const isErrorDialogOpen = ref(false);

export function openSummaryDialog() {
    isSummaryDialogOpen.value = true;
}

export function closeSummaryDialog() {
    isSummaryDialogOpen.value = false;
}

async function showConfirmMatchDeselectDialog() {
    const session = useSessionStore();
    if (session.hasSelectedMatch && !session.hasConfirmed) {
        if (
            (await openConfirmDialog({
                title: 'Are you sure?',
                description:
                    'By updating your details, we will find new matches for you, which will replace your current selection. Continue to update?',
                confirm: 'Yes, edit details',
                cancel: 'Cancel',
            })) !== 'confirm'
        )
            return false;
    }
    return true;
}

export async function openPickupDialog() {
    closeSummaryDialog();
    if (!(await showConfirmMatchDeselectDialog())) return;
    isPickupDialogOpen.value = true;
}

export async function closePickupDialog() {
    isPickupDialogOpen.value = false;
}

export async function openDeliveryDialog() {
    closeSummaryDialog();
    if (!(await showConfirmMatchDeselectDialog())) return;
    isDeliveryDialogOpen.value = true;
}

export function closeDeliveryDialog() {
    isDeliveryDialogOpen.value = false;
}

export async function openPickupAddressDialog() {
    closeSummaryDialog();
    if (!(await showConfirmMatchDeselectDialog())) return;
    isPickupAddressDialogOpen.value = true;
}

export function closePickupAddressDialog() {
    isPickupAddressDialogOpen.value = false;
}

export async function openDeliveryAddressDialog() {
    closeSummaryDialog();
    if (!(await showConfirmMatchDeselectDialog())) return;
    isDeliveryAddressDialogOpen.value = true;
}

export function closeDeliveryAddressDialog() {
    isDeliveryAddressDialogOpen.value = false;
}

export async function openDateDialog() {
    closeSummaryDialog();
    if (!(await showConfirmMatchDeselectDialog())) return;
    closeSummaryDialog();
    isDateDialogOpen.value = true;
}

export function closeDateDialog() {
    isDateDialogOpen.value = false;
}

export async function openSpaceDialog() {
    closeSummaryDialog();
    if (!(await showConfirmMatchDeselectDialog())) return;
    isSpaceDialogOpen.value = true;
}

export function closeSpaceDialog() {
    isSpaceDialogOpen.value = false;
}

export async function openCustomItemDialog() {
    closeSummaryDialog();
    isCustomItemDialogOpen.value = true;
}

export function closeCustomItemDialog() {
    isCustomItemDialogOpen.value = false;
}

export async function openRequiredSpaceDialog() {
    closeSummaryDialog();
    isRequiredSpaceDialogOpen.value = true;
}

export function closeRequiredSpaceDialog() {
    isRequiredSpaceDialogOpen.value = false;
}

export function openInlineCustomItemDialog() {
    isInlineCustomItemDialogOpen.value = true;
}

export function closeInlineCustomItemDialog() {
    isInlineCustomItemDialogOpen.value = false;
}

export function openInlineRequiredSpaceDialog() {
    isInlineRequiredSpaceDialogOpen.value = true;
}

export function closeInlineRequiredSpaceDialog() {
    isInlineRequiredSpaceDialogOpen.value = false;
}

export const isUtilitiesDataCaptured = ref(false);
export async function openUtilitiesDialog() {
    isUtilitiesDialogOpen.value = true;
}

export function closeUtilitiesDialog() {
    isUtilitiesDialogOpen.value = false;
}

export function openInsuranceDialog() {
    isInsuranceDialogOpen.value = true;
}

export const isInsuranceConfirmDialogOpen = ref(false);
const insuranceConfirmDialogChoice = ref();
export async function openInsuranceConfirmDialog() {
    isInsuranceConfirmDialogOpen.value = true;
    insuranceConfirmDialogChoice.value = undefined;

    await until(isInsuranceConfirmDialogOpen).toBe(false);

    return insuranceConfirmDialogChoice.value;
}

export async function closeInsuranceDialog(withConfirm = true) {
    if (withConfirm && !(await openInsuranceConfirmDialog())) {
        return;
    } else {
        isInsuranceDialogOpen.value = false;
    }
}

export function closeInsuranceConfirmDialog(choice) {
    insuranceConfirmDialogChoice.value = choice;
    isInsuranceConfirmDialogOpen.value = false;
}

export function openInsurancePaymentDialog() {
    isInsurancePaymentDialogOpen.value = true;
}

export function closeInsurancePaymentDialog() {
    isInsurancePaymentDialogOpen.value = false;
}

export function openCompanyInfoDialog() {
    isCompanyInfoDialogOpen.value = true;
}

export function closeCompanyInfoDialog() {
    isCompanyInfoDialogOpen.value = false;
}

export function openTrustDialog() {
    isTrustDialogOpen.value = true;
}

export function closeTrustDialog() {
    isTrustDialogOpen.value = false;
}

export function openReadonlyMatchListDialog() {
    isReadonlyMatchListDialogOpen.value = true;
}

export function closeReadonlyMatchListDialog() {
    isReadonlyMatchListDialogOpen.value = false;
}

export function openReadonlyCompanyInfoDialog() {
    isReadonlyCompanyInfoDialogOpen.value = true;
}

export function closeReadonlyCompanyInfoDialog() {
    isReadonlyCompanyInfoDialogOpen.value = false;
}

export function openVideoDialog(video) {
    const session = useSessionStore();
    session.videoEmbed = video;
    isVideoDialogOpen.value = true;
}

export function closeVideoDialog() {
    isVideoDialogOpen.value = false;
}

export function openTaxiboxDialog() {
    isTaxiboxDialogOpen.value = true;
}

export function closeTaxiboxDialog() {
    isTaxiboxDialogOpen.value = false;
}

export async function openConfirmDialog(label) {
    confirmDialogLabel.value = typeof label === 'string' ? { title: label } : label;
    confirmDialogChoice.value = null;
    isConfirmDialogOpen.value = true;

    await until(isConfirmDialogOpen).toBe(false);

    return confirmDialogChoice.value;
}

export function closeConfirmDialog(choice) {
    confirmDialogChoice.value = choice;
    isConfirmDialogOpen.value = false;
}

export async function openErrorDialog(data) {
    errorDialogData.value = data;
    isErrorDialogOpen.value = true;

    await until(isErrorDialogOpen).toBe(false);
}

export function closeErrorDialog() {
    isErrorDialogOpen.value = false;
}
