<template>
    <div class="flex flex-col gap-4">
        <span class="text-base font-medium">Booking details</span>
        <!-- Price breakdown -->
        <ul
            v-if="match.breakdown"
            class="flex flex-col gap-2"
        >
            <li
                v-for="(item, bIndex) in match.breakdown"
                :key="bIndex"
                class="flex justify-between text-xs font-medium"
            >
                <span
                    :class="{
                        'pl-2': item.type === BreakdownType.ACCESS,
                    }"
                    class="text-[#9B9B9B]"
                >
                    {{ item.label }}
                </span>
                <span>{{ item.value }}</span>
            </li>
        </ul>
        <div class="-mx-8 -mb-4 flex items-center justify-between bg-gray-100 p-4">
            <div
                v-if="match.isFixed"
                class="text-xl font-medium leading-none"
            >
                {{ match.estimated_total?.formatted }}
            </div>
            <MuvalButton
                v-if="!readonly"
                class="ml-auto font-medium"
                tertiary
                :loading="match.selecting"
                @click="handleSelect"
            >
                Select and continue
            </MuvalButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type MoveMatch, BreakdownType } from '@/types';
import { useResultsStore } from '@/store';

interface Props {
    match: MoveMatch;
    readonly?: Boolean;
}
const props = defineProps<Props>();

const results = useResultsStore();

const handleSelect = async () => {
    await results.selectMatch(props.match);
};
</script>
