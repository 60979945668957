/**
 * Vue Main Entry Point
 *
 * @package Muval Book Project
 * @copyright 2024 Muval Pty Ltd. All rights reserved.
 */

import { createApp, markRaw } from 'vue';
import App from './App.vue';
import router from './router';
import { pinia } from './store';
import Notifications from '@kyvg/vue3-notification';
import { VueQueryPlugin } from '@tanstack/vue-query';

import '@lottiefiles/lottie-player';
import './style/tailwind.css';

// Plugins

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import filters from './utilities/filters';

import { createSentry } from './plugins/sentry';
import posthogPlugin from './plugins/posthog';
import axios from './plugins/axios';
import VueTippy from 'vue-tippy';
import 'virtual:svg-icons-register';

const app = createApp(App);
app.use(posthogPlugin);
createSentry({ app, router });

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

app.use(VueQueryPlugin);

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$filters = filters;

app.use(pinia);
app.use(Notifications);
app.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'Tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
});
app.use(axios);
app.use(router);
app.mount('#app');
