<template>
    <form
        class="flex flex-col gap-4"
        @submit="onSubmit"
    >
        <MuvalPickupDate :form="form" />
        <div class="ml-auto flex gap-4">
            <MuvalButton
                borderless
                tertiary
                @click.prevent="closeDateDialog"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                type="submit"
                primary
                alternate
                :disabled="isLoading"
                :loading="isLoading"
            >
                Save new dates
            </MuvalButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useSessionStore } from '@/store';
import { closeDateDialog } from '@/composables/dialog';

const sessionStore = useSessionStore();
const initialValues = ref({
    move: {
        pickup_date_from: sessionStore.getPrimaryProduct.pickup_date_from,
        pickup_date_by: sessionStore.getPrimaryProduct.pickup_date_by,
        pickup_date_interval: sessionStore.getPrimaryProduct.pickup_date_interval,
        pickup_date_type: sessionStore.getPrimaryProduct.pickup_date_type,
        pickup_date_flexibility: sessionStore.getPrimaryProduct.pickup_date_flexibility,
    },
});

const form = useForm({
    initialValues: initialValues.value,
});

const router = useRouter();
const isLoading = ref(false);
const onSubmit = form.handleSubmit(async (values) => {
    isLoading.value = true;
    await window.axios.patch(`/customers/book/move/${sessionStore.getMoveId}/pickup/date`, {
        ...values,
    });

    await sessionStore.retrieve();
    if (sessionStore.hasReachedResults) {
        router.replace({ name: 'Loading' });
    }

    closeDateDialog();
    isLoading.value = false;
});
</script>
