<template>
    <div class="relative block h-input w-full">
        <Combobox
            v-model="address"
            as="div"
            class="relative block w-full skeleton:hidden"
        >
            <ComboboxInput
                :id="props.vid"
                ref="input"
                type="text"
                class="peer block h-input w-full resize-y overflow-y-auto rounded-muval-1 border px-3.5 text-sm placeholder-transparent focus:border-focus focus:outline-none focus:ring-0"
                :class="[getStyles]"
                :placeholder="placeholder"
                autocomplete="off"
                aria-autocomplete="none"
                @enter="handleChange"
                @change="handleChange"
            />
            <div
                v-if="label"
                class="peer pointer-events-none absolute -top-2 left-2 z-10 line-clamp-1 text-xs text-gray-400 transition-all duration-75 peer-placeholder-shown:top-[0.89rem] peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-focus"
            >
                <span class="px-1">
                    {{
                        validGoogleAddress ? label
                        : placeholder ? placeholder
                        : label
                    }}
                </span>
                <div class="absolute top-2 -z-10 h-0.5 w-full bg-white px-2"></div>
            </div>
            <div>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <template v-if="fetching">
                        <SvgIcon
                            class="h-4 w-4 animate-spin text-muval-gray-4"
                            name="system-loading"
                        />
                    </template>
                    <template v-if="errorMessage">
                        <SvgIcon
                            class="h-4 w-4"
                            name="input_error"
                        />
                    </template>
                </div>
            </div>
            <div
                class="pointer-events-none absolute -bottom-5 left-0 w-full truncate whitespace-nowrap text-xxs"
                :class="errorMessage ? 'text-error' : ''"
            >
                <span class="text-xs">{{ errorMessage }}</span>
            </div>
            <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                class="relative w-full"
            >
                <ComboboxOptions
                    v-if="places && places.length"
                    :key="places.length"
                    class="absolute z-50 w-full rounded-b-muval-1 shadow"
                >
                    <ComboboxOption
                        v-for="(place, index) in places"
                        :key="`place-option-${index}`"
                        v-slot="{ selected, active }"
                        :data-cy="`place-option-${index}`"
                        :value="place"
                    >
                        <AddressOption
                            :option="place"
                            :isActive="selected || active"
                        />
                    </ComboboxOption>
                    <div class="flex w-full items-center justify-end gap-1 bg-muval-gray-6 p-2">
                        <span class="text-xs">powered by</span>
                        <img
                            class="w-12"
                            src="/google_on_white_hdpi.png"
                            alt="google_logo"
                        />
                    </div>
                </ComboboxOptions>
                <div
                    v-else-if="isSearchableAddress && !hasError && !fetching && debounceCleared"
                    class="absolute z-50 w-full rounded-b-muval-1 bg-muval-gray-6 px-4 pt-4 text-xs shadow"
                >
                    <span>No results found for "{{ googlePlacesSearch }}"</span>
                    <div class="flex w-full items-center justify-end gap-1 bg-muval-gray-6 p-2">
                        <span class="text-xs">powered by</span>
                        <img
                            class="w-12"
                            src="/google_on_white_hdpi.png"
                            alt="google_logo"
                        />
                    </div>
                </div>
                <div
                    v-else-if="hasError"
                    class="absolute z-50 w-full rounded-b-muval-1 bg-muval-gray-6 p-4 text-xs text-muval-red-1 shadow"
                >
                    <span>There was an error with the Google Places API please refresh your page.</span>
                </div>
            </TransitionRoot>
        </Combobox>

        <div
            class="absolute inset-0 z-50 hidden h-full w-full bg-muval-gray-5 skeleton:block"
            :class="loading"
        ></div>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { idGen } from '@/utilities/idGen';
import AddressOption from '@/inputs/AddressOption.vue';
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue';
import useGoogleAutocompleteService from '@/composables/useGoogleAutocomplete';
import { debouncedWatch } from '@vueuse/core';
import { useAnalyticsStore } from '@/store';
const analytics = useAnalyticsStore();

const props = defineProps({
    modelValue: {
        type: [String, null],
        required: false,
        default: null,
    },
    label: {
        type: [String, null],
        required: false,
        default: null,
    },
    placeholder: {
        type: [String, null],
        required: false,
        default: null,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    autocomplete: {
        type: String,
        required: false,
        default: 'off',
    },
});

const input = ref(null);
const { fetchResults, fetchDetails, hasError, loading, fetching } = useGoogleAutocompleteService();
const validGoogleAddress = ref(false);
const initialAddress = ref(true);
const debounceCleared = ref(false);
const address = computed({
    get: () => {
        return inputValue.value;
    },
    set: (value) => {
        if (value == '' || value == null) {
            inputValue.value = '';
            googlePlacesSearch.value = '';
            validGoogleAddress.value = false;
            googlePlaceId.value = null;
        } else if (value && value.description && value.place_id) {
            inputValue.value = value.description;
            googlePlacesSearch.value = value.description;
            validGoogleAddress.value = true;
            googlePlaceId.value = value.place_id;
        }
    },
});

const googlePlacesSearch = ref(null);
const googlePlacesSearchCount = ref(0);
const googlePlaceId = ref(null);
const { value: inputValue, errorMessage } = useField(props.vid, undefined);

const getStyles = computed(() => {
    if (props.disabled) {
        return 'bg-muval-gray-5 border-muval-gray-4 cursor-not-allowed';
    } else if (errorMessage.value) {
        return 'bg-white border-error';
    } else {
        return 'bg-white border-muval-gray-4';
    }
});

const isSearchableAddress = computed(() => {
    if (
        googlePlacesSearch.value &&
        googlePlacesSearch.value.length &&
        (googlePlacesSearch.value || '').match(/[A-Za-z]{3,}/)
    ) {
        return true;
    }

    return false;
});

function initSearch() {
    // if the value of the input is now matching a result, no need to search again
    if (places.value?.find((place) => place?.description === googlePlacesSearch.value)) {
        // request place details to finalise session
        fetchDetails(googlePlaceId.value);

        // track total number of autocomplete requests in analytics
        analytics.addressSearch(googlePlacesSearchCount.value);

        // skip searching again
        return;
    }

    googlePlacesSearchCount.value++;

    fetchResults(googlePlacesSearch.value, (results) => {
        // Remove results of type: administrative area level 1 (state), country or point of interest
        const filteredResults = results?.filter(function (prediction) {
            return !(
                prediction.types.includes('administrative_area_level_1') ||
                prediction.types.includes('country') ||
                prediction.types.includes('point_of_interest') ||
                prediction.types.includes('neighborhood')
            );
        });

        places.value = filteredResults;
    });
}

const places = ref([]);

watch(googlePlacesSearch, (newVal) => {
    if (!newVal) {
        places.value = [];
    }
});

watch(
    inputValue,
    (newVal) => {
        if (newVal) {
            googlePlacesSearch.value = newVal;
            validGoogleAddress.value = true;
        } else {
            googlePlacesSearch.value = null;
        }
    },
    { immediate: true },
);

function handleChange(event) {
    // Flag the debouncer as pending - to avoid no results message appearing early
    debounceCleared.value = false;

    // Flag the address as not prefilled
    initialAddress.value = false;

    if (event.target.value) {
        googlePlacesSearch.value = event.target.value;
        validGoogleAddress.value = false;
    } else {
        // does not clear the field if input deleted, reverts back - see crm to clear the field
        googlePlacesSearch.value = null;
        validGoogleAddress.value = false;
    }

    // Unfocus the input after the user has selected an address
    if (validGoogleAddress.value) {
        input.value.blur();
    }
}

debouncedWatch(
    googlePlacesSearch,
    () => {
        // Skip search if we're prefilling - this flag is cleared when the field is updated by the user
        if (initialAddress.value) {
            return;
        }

        // Skip searching until the user has entered a letter
        if (!isSearchableAddress.value) {
            return;
        }

        // Flag the debouncer as cleared
        debounceCleared.value = true;

        // Search google place api for matches
        initSearch();
    },
    {
        debounce: 300, // wait until the user pauses for 300 ms before searching to avoid flooding requests
    },
);
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
</style>
