<template>
    <form
        class="scroll-shadows flex flex-col gap-4"
        @submit="onSubmit"
    >
        <span class="font-header text-lg font-medium">Edit pickup details</span>
        <MuvalAddress vid="move.pickup_address" />

        <template v-if="showPickupDwelling">
            <!-- Pickup Dwelling Type -->
            <div class="flex flex-col gap-4">
                <span class="font-header font-medium">Property details</span>
                <RadioGroup v-model="selectedDwellingType">
                    <RadioGroupLabel class="sr-only">Property type</RadioGroupLabel>
                    <div class="col-span-4 grid grid-cols-4 gap-x-4 gap-y-3">
                        <RadioGroupOption
                            v-for="(item, key) in propertyTypes"
                            :key="item"
                            v-slot="{ checked }"
                            as="div"
                            class="radio group col-span-2 md:col-span-1"
                            :value="key"
                            :data-cy="`select-${key}`"
                        >
                            <div
                                class="radio-large-content"
                                :class="checked ? 'radio-checked' : 'radio-unchecked'"
                            >
                                <SvgIcon
                                    class="m-auto h-14 w-14"
                                    :name="`pictogram-${item}`"
                                />
                                <span class="radio-small-label-large">{{ item }}</span>
                            </div>
                        </RadioGroupOption>
                    </div>
                </RadioGroup>
            </div>

            <!-- Pickup Space -->
            <template v-if="values.move.pickup_dwelling !== 'STORAGE'">
                <RadioGroup
                    v-model="pickupBedrooms"
                    class="flex flex-col gap-4"
                >
                    <RadioGroupLabel class="sr-only">Number of bedrooms</RadioGroupLabel>
                    <span class="font-header text-sm">Number of bedrooms</span>
                    <div class="flex gap-2">
                        <RadioGroupOption
                            v-for="item in options"
                            :key="item.value"
                            v-slot="{ checked }"
                            as="div"
                            class="radio group"
                            :value="item.value"
                            :data-cy="`select-${item.value}`"
                        >
                            <div
                                class="radio-small-content"
                                :class="checked ? 'radio-checked' : 'radio-unchecked'"
                            >
                                <span class="radio-small-label">{{ item.label }}</span>
                            </div>
                        </RadioGroupOption>
                    </div>
                </RadioGroup>
            </template>
            <template v-else>
                <div class="flex w-full flex-col justify-start gap-4 md:w-3/5">
                    <span class="font-sm font-header">Storage area</span>
                    <MuvalInput
                        label="Floor area in square meters"
                        vid="move.pickup_area"
                        inputType="number"
                        infoType="m&sup2;"
                    />
                </div>
            </template>
        </template>

        <!-- Pickup Access -->
        <div class="flex flex-col gap-4">
            <span class="font-header font-medium">Property access</span>
            <MuvalAccessTags
                :inline="false"
                vid="move.pickup_access"
                :selectOptions="pickupAccessOptions"
            />
        </div>

        <footer class="w-ful sticky -bottom-6 z-50 -mb-6 flex items-center justify-center bg-white py-4">
            <div class="ml-auto flex gap-4">
                <MuvalButton
                    borderless
                    tertiary
                    @click.prevent="closePickupDialog"
                >
                    Cancel
                </MuvalButton>
                <MuvalButton
                    type="submit"
                    primary
                    alternate
                    :disabled="isSubmitting"
                    :loading="isSubmitting"
                >
                    Save
                </MuvalButton>
            </div>
        </footer>
    </form>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useSessionStore, useAppStore } from '@/store';
import { closePickupDialog } from '@/composables/dialog';

const appStore = useAppStore();
const sessionStore = useSessionStore();

const showPickupDwelling = computed(() => {
    return sessionStore.getBookingType === 'WHOLE_HOME' || sessionStore.getBookingType === 'FEW_ROOMS';
});

const pickupAccessOptions = ref(appStore.access_tags ?? []);

const initialValues = ref({
    move: {
        pickup_address: sessionStore.getPrimaryProduct.pickup_address,
        pickup_dwelling: sessionStore.getPrimaryProduct.pickup_dwelling,
        pickup_access:
            sessionStore.getPrimaryProduct.pickup_access.map((item) => {
                return {
                    id: item.id,
                    ...item.accessTag,
                    value: item.value,
                };
            }) ?? [],
    },
});

if (sessionStore.getPrimaryProduct.pickup_bedrooms !== undefined) {
    initialValues.value.move.pickup_bedrooms = parseInt(sessionStore.getPrimaryProduct.pickup_bedrooms);
}

if (sessionStore.getPickupDwelling === 'STORAGE') {
    initialValues.value.move.pickup_area = sessionStore.getPrimaryProduct.pickup_area;
} else {
    initialValues.value.move.pickup_area = null;
}

const propertyTypes = computed(() => {
    const residentialPropertyType = { ...appStore.predefined.residential_property_types };
    delete residentialPropertyType.OFFICE;
    return residentialPropertyType;
});

const { handleSubmit, defineField, setErrors, isSubmitting, values } = useForm({
    initialValues: initialValues.value,
});

const options = computed(() => {
    if (values.move.pickup_dwelling === 'APARTMENT') {
        return [
            {
                label: 'Studio',
                value: 0,
            },
            {
                label: '1',
                value: 1,
            },
            {
                label: '2',
                value: 2,
            },
            {
                label: '3',
                value: 3,
            },
            {
                label: '4',
                value: 4,
            },
            {
                label: '5+',
                value: 5,
            },
        ];
    } else {
        return [
            {
                label: '1',
                value: 1,
            },
            {
                label: '2',
                value: 2,
            },
            {
                label: '3',
                value: 3,
            },
            {
                label: '4',
                value: 4,
            },
            {
                label: '5+',
                value: 5,
            },
        ];
    }
});

const [selectedDwellingType] = defineField('move.pickup_dwelling');
const [pickupBedrooms] = defineField('move.pickup_bedrooms');

const router = useRouter();
const onSubmit = handleSubmit(async (values) => {
    try {
        await window.axios.patch(`/customers/book/move/${sessionStore.getMoveId}/pickup/details`, {
            ...values,
        });

        await sessionStore.retrieve();
        if (sessionStore.hasReachedResults) {
            router.replace({ name: 'Loading' });
        }

        closePickupDialog();
    } catch (error) {
        setErrors(error.errors);
    }
});
</script>
