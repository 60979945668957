import { defineStore } from 'pinia';
import { useSessionStore } from '@/store/session';

export const useSelectedMatchStore = defineStore('selectedMatch', {
    state: () => {
        return {
            data: null,
        };
    },
    actions: {},
    getters: {
        getMatch: () => {
            const session = useSessionStore();
            return session.getSelectedMatch;
        },

        hasJob: () => {
            const session = useSessionStore();
            return session.getJob !== null;
        },

        getJob: () => {
            const session = useSessionStore();
            return session.getJob;
        },

        getPricePerUnit: (state) => {
            if (!state.hasJob) {
                return state.getMatch.price_per_unit.formatted;
            }
            return null;
        },

        getMinPrice: (state) => {
            if (!state.hasJob) {
                return state.getMatch.min_price.formatted;
            }
            return null;
        },

        isMinPriceInvoked(state) {
            if (state.getMatch) {
                return state.getMatch.min_price_invoked;
            }
        },
    },
});
