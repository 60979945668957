<template>
    <template v-if="!match?.isAnonymous">
        <div class="flex w-fit flex-col items-start gap-1 leading-none">
            <a
                class="max-w-[130px] truncate text-left text-sm font-medium leading-none hover:underline"
                @click.stop.prevent="openCompanyInfo"
            >
                {{ company?.name }}
            </a>
            <div class="flex items-center gap-1.5 whitespace-nowrap">
                <ReviewsTag v-bind="{ match: match, company: company }" />
                <button
                    :class="[
                        match.hasCallback ? 'pointer-events-none' : 'pointer-events-auto',
                        // james requested so always on one line
                        !match.isAnonymous ? 'flex lg:hidden xl:flex' : 'flex',
                    ]"
                    class="cursor-pointer items-center gap-0.5 text-xs font-normal text-muval-gray-1 hover:underline"
                    @click.stop.prevent="results.requestCallback(match)"
                >
                    <SvgIcon
                        name="system-callback"
                        class="h-3 w-3"
                    />
                    {{ !match.hasCallback ? 'Request call' : 'Requested' }}
                </button>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="flex h-[34px] min-w-[90px] flex-col justify-between whitespace-nowrap">
            <ReviewsTag v-bind="{ match: match, company: company }" />
            <div class="flex items-center gap-1.5 whitespace-nowrap">
                <span class="text-left text-xs leading-none text-muval-gray-3">
                    <span
                        class="font-medium text-muval-gray-1"
                        v-text="company?.metric.header"
                    />
                    {{ company?.metric.description }}
                </span>
                <button
                    :class="[match.hasCallback ? 'pointer-events-none' : 'pointer-events-auto']"
                    class="flex cursor-pointer items-center gap-0.5 text-xs font-normal text-muval-gray-1 hover:underline lg:hidden xl:flex"
                    @click.stop.prevent="results.requestCallback(match)"
                >
                    <SvgIcon
                        name="system-callback"
                        class="h-3 w-3"
                    />
                    {{ !match.hasCallback ? 'Request call' : 'Requested' }}
                </button>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import axios from 'axios';
import type { MoveMatch, Company } from '@/types';
import { useResultsStore } from '@/store';
import { openCompanyInfoDialog, openReadonlyCompanyInfoDialog } from '@/composables/dialog';

interface Props {
    match: MoveMatch;
    company?: Company;
    readonly?: Boolean;
}

const props = defineProps<Props>();
const results = useResultsStore();
const openCompanyInfo = async () => {
    if (!props.company) return console.error('No company provided');

    const url =
        props.company?.profile_slug ?
            `https://www.muval.com.au/moving-companies/${props.company?.profile_slug}?noNav=1`
        :   '';
    if (!url) return console.error('Company profile url not found');

    try {
        const response = await axios.head(url);
        if (response.status === 200) {
            results.selectedCompany = props.company;
            if (props.readonly) {
                openReadonlyCompanyInfoDialog();
            } else {
                openCompanyInfoDialog();
            }
        } else {
            console.error('Company profile url not found');
        }
    } catch (error) {
        console.error('Company profile url not found');
    }
};
</script>
