<template>
    <form
        class="flex flex-col gap-4"
        @submit="onSubmit"
    >
        <span class="font-header text-lg font-medium">Edit delivery details</span>
        <MuvalAddress vid="move.delivery_address" />

        <!-- Delivery Access -->
        <div class="flex flex-col gap-4">
            <span class="font-header font-medium">Property access</span>
            <MuvalAccessTags
                :inline="false"
                vid="move.delivery_access"
                :selectOptions="deliveryAccessOptions"
            />
        </div>

        <div class="ml-auto flex gap-4">
            <MuvalButton
                borderless
                tertiary
                @click.prevent="closeDeliveryDialog"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                type="submit"
                primary
                alternate
                :disabled="isSubmitting"
                :loading="isSubmitting"
            >
                Save
            </MuvalButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useSessionStore, useAppStore } from '@/store';
import { closeDeliveryDialog } from '@/composables/dialog';

const appStore = useAppStore();
const sessionStore = useSessionStore();

const deliveryAccessOptions = ref(appStore.access_tags ?? []);

const initialValues = ref({
    move: {
        delivery_address: sessionStore.getPrimaryProduct.delivery_address,
        delivery_access:
            sessionStore.getPrimaryProduct.delivery_access.map((item) => {
                return {
                    id: item.id,
                    ...item.accessTag,
                    value: item.value,
                };
            }) ?? [],
    },
});

const { handleSubmit, resetForm, setErrors, isSubmitting } = useForm({
    initialValues: initialValues.value,
});

const router = useRouter();

const onSubmit = handleSubmit(async (values) => {
    try {
        await window.axios.patch(`/customers/book/move/${sessionStore.getMoveId}/delivery/details`, {
            ...values,
        });

        await sessionStore.retrieve();
        if (sessionStore.hasReachedResults) {
            router.replace({ name: 'Loading' });
        }

        closeDeliveryDialog();
    } catch (error) {
        setErrors(error.errors);
    }
});

onMounted(() => {
    resetForm({
        values: initialValues.value,
    });
});
</script>
