<template>
    <div class="relative flex h-full w-full items-center justify-start gap-4 rounded-md bg-white p-2">
        <div class="match-loading h-10 w-10 flex-shrink-0"></div>

        <div class="relative mb-auto flex h-full w-fit flex-grow-0 flex-col items-start gap-2">
            <div class="match-loading h-4 w-32 md:w-32">
                <div></div>
            </div>
            <div class="flex w-full flex-col gap-2">
                <div class="match-loading h-3 w-20 rounded-full"></div>
            </div>
        </div>

        <div class="relative mb-auto mr-auto flex h-full w-full flex-grow flex-col items-start gap-2">
            <div class="match-loading h-4 w-32 md:w-32">
                <div></div>
            </div>
            <div class="flex w-full flex-col gap-2">
                <div class="match-loading h-3 w-40 rounded-full"></div>
            </div>
        </div>

        <div class="absolute right-2 top-2 hidden md:flex">
            <div class="match-loading h-10 w-32 !rounded-full"></div>
        </div>
    </div>
</template>

<style scoped>
.match-loading {
    @apply animate-pulse rounded-md bg-[#F0F0F0];
}
</style>
