<template>
    <a
        v-if="slaask.show"
        class="my-auto block cursor-pointer rounded-full px-1 py-1.5 text-sm text-brand lg:hidden"
        @click="slaask.toggleChat"
    >
        <SvgIcon
            class="h-5 w-5 text-brand"
            :name="slaask.chatOpen ? 'system-close' : 'system-chat'"
        />
    </a>
</template>

<script setup>
import { useAppStore } from '@/store';

const { slaask } = useAppStore();
</script>
