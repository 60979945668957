<template>
    <div
        class="relative p-0"
        :class="calculatedWidth ? 'w-fit' : 'w-full'"
    >
        <div
            v-if="!editing"
            class="flex flex-col"
        >
            <span class="text-base font-light text-gray-500">{{ label }}</span>
            {{ inputValue }}
        </div>
        <div
            v-else-if="editing"
            class="relative flex w-full cursor-pointer select-none"
        >
            <input
                :id="inputId"
                ref="input"
                :data-cy="vid"
                :disabled="disabled"
                :name="vid"
                :type="inputType"
                class="peer block w-full resize-y overflow-y-auto rounded-muval-1 border px-3.5 placeholder-transparent focus:border-focus focus:outline-none focus:ring-0"
                :class="[getStyles]"
                :size="width"
                :placeholder="placeholder ? placeholder : label"
                :value="inputValue"
                :autocomplete="canAutoComplete"
                :readonly="read"
                @input="handleInput"
                @change="handleChange"
                @blur="handleBlur"
            />
            <slot></slot>
            <div
                v-if="label"
                class="peer pointer-events-none absolute left-2 z-10 line-clamp-1 -translate-y-1/2 text-sm text-muval-gray-3 transition-all duration-100 peer-placeholder-shown:left-3 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-muval-gray-3 peer-focus:left-2 peer-focus:top-0 peer-focus:text-sm peer-focus:text-focus"
            >
                <span class="px-1">{{ label }}</span>
                <div
                    class="absolute top-2.5 -z-10 h-0.5 w-full"
                    :class="getStyles"
                ></div>
            </div>

            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <template v-if="cubic">
                    <span>
                        m
                        <sup>3</sup>
                    </span>
                </template>
                <template v-if="infoType">
                    <span>{{ infoType }}</span>
                </template>
                <template v-if="errorMessage">
                    <SvgIcon
                        class="h-4 w-4"
                        name="input_error"
                    />
                </template>
            </div>
        </div>
        <div
            class="-bottom-4.5 pointer-events-none absolute left-0 w-full truncate whitespace-nowrap text-xs"
            :class="errorMessage ? 'text-red-500' : ''"
        >
            <span class="ml-2 text-xs">{{ errorMessage }}</span>
        </div>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { idGen } from '@/utilities/idGen';
import SvgIcon from '../utilities/SvgIcon.vue';

const props = defineProps({
    label: {
        type: [String, null],
        required: false,
        default: null,
    },
    placeholder: {
        type: [String, null],
        required: false,
        default: null,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    read: {
        type: Boolean,
        required: false,
        default: false,
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    updateOn: {
        type: String,
        required: false,
        default: 'blur',
    },
    inputType: {
        type: String,
        required: false,
        default: 'text',
    },
    cubic: {
        type: Boolean,
        required: false,
        default: false,
    },
    editing: {
        type: Boolean,
        required: false,
        default: true,
    },
    infoType: {
        type: [String, null],
        required: false,
        default: null,
    },
    calculatedWidth: {
        type: Boolean,
        required: false,
        default: null,
    },
    autocomplete: {
        type: Boolean,
        required: false,
        default: false,
    },
    delayValidation: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const inputId = idGen(6);

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
} = useField(toRef(props, 'vid'), undefined, { validateOnValueUpdate: !props.delayValidation });

const canAutoComplete = computed(() => {
    if (props.autocomplete && !props.read) {
        return 'on';
    } else {
        return 'off';
    }
});

const handleInput = (e) => {
    if (!props.delayValidation) {
        handleChange(e);
    }
};

const width = computed(() => {
    if (props.calculatedWidth) {
        let width = props.infoType?.length ? props.infoType.length : 0;
        if (props.placeholder) {
            return props.placeholder.length + width;
        } else if (props.label) {
            return props.label.length + width;
        } else {
            return 100;
        }
    }
    return null;
});

const getStyles = computed(() => {
    if (props.disabled) {
        return 'bg-muval-gray-5 border-muval-gray-4 cursor-not-allowed';
    } else if (errorMessage.value) {
        return 'bg-white border-error';
    } else {
        return 'bg-white border-muval-gray-4';
    }
});
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
</style>
