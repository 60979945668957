import { ref, onMounted } from 'vue';
import { useSessionStore, useAnalyticsStore } from '@/store';

export default function useSlaask() {
    const session = useSessionStore();
    const analytics = useAnalyticsStore();
    const slaaskLoaded = ref(false);
    const isOpen = ref(false);
    const show = ref(false);

    onMounted(() => {
        if (import.meta.env.VITE_ENV != 'production') {
            console.log('ℹ️ unable to load Slaask due to environment configuration');

            return;
        }

        if (slaaskLoaded.value) {
            return;
        }

        window._slaaskSettings = {
            identify: function () {
                return {
                    id: session.getOrderRef,
                    name: session.getCustomerFullName,
                    email: session.getCustomerEmail,
                };
            },
            key: 'spk-202e390f-ae01-4ef3-97ab-3db7ed7a8225',
        };

        const chatLoaderScript = document.createElement('script');
        chatLoaderScript.src = 'https://cdn.slaask.com/chat_loader.js';
        chatLoaderScript.async = true;
        chatLoaderScript.onload = () => {
            slaaskLoaded.value = true;

            document.addEventListener(
                'slaask.ready',
                function () {
                    show.value = window._slaask.isLive();
                },
                false,
            );

            document.addEventListener(
                'slaask.open',
                function () {
                    analytics.chatStarted();
                    isOpen.value = true;
                },
                false,
            );

            document.addEventListener(
                'slaask.close',
                function () {
                    isOpen.value = false;
                },
                false,
            );
        };

        document.head.appendChild(chatLoaderScript);
    });

    function toggleChat() {
        window._slaask.isOpen() ? window._slaask.hide() : window._slaask.show();
    }

    return { loaded: slaaskLoaded, chatOpen: isOpen, toggleChat, show };
}
