import { defineStore } from 'pinia';
import { useSessionStore } from '@/store/session';

export const usePaymentStore = defineStore('payment', () => {
    const session = useSessionStore();

    const data = ref({
        service_type: null,
        stripeCustomerId: null,
        current_service: null,
        current_payment: null,
        payments: null,
        total: null,
        loading: false,
        pending_amount: null,
    });

    async function list(service_type = null) {
        data.value.loading = true;
        if (!data.value.service_type) {
            data.value.service_type = service_type;
        }
        try {
            const response = await window.axios.get(`/customers/book/orders/${session.getOrderId}/payments`);

            data.value.stripeCustomerId = response.data.stripe_customer_id;
            data.value.current_service = response.data.current_service;
            data.value.current_payment = response.data.current_payment;
            data.value.payments = response.data.payments;
            data.value.total = response.data.total;
            data.value.pending_amount = response.data.pending_amount;
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            data.value.loading = false;
        }
    }

    // Get a payment
    async function show(orderPaymentId) {
        try {
            const { data } = await window.axios.get(
                `/customers/book/orders/${session.getOrderId}/payments/${orderPaymentId}`,
            );

            if (data) {
                return {
                    payment: data.payment,
                    clientSecret: data.client_secret,
                    stripeCustomerId: data.stripe_customer_id,
                    orderPaymentId: data.order_payment_id,
                };
            }
        } catch (error) {
            console.log('Error retrieving payment', error);
        }
    }

    async function retrievePaymentSecret(orderPaymentId = null) {
        let url = `/customers/book/orders/${session.getOrderId}/payments/secret`;

        if (orderPaymentId !== null) {
            url += `/${orderPaymentId}`;
        }

        const { data } = await window.axios.get(url);

        if (data) {
            return {
                payment: data.payment,
                clientSecret: data.client_secret,
                stripeCustomerId: data.stripe_customer_id,
            };
        }
    }

    async function retrySetup(paymentId, setupIntent) {
        const { data } = await window.axios.post(`/customers/book/orders/${session.getOrderId}/payments/${paymentId}`, {
            pm_id: setupIntent.payment_method,
            seti_id: setupIntent.id,
        });

        if (data.payment.status === 'PAID') {
            await session.retrieve();
            return true;
        }
    }

    async function flagBankDeposit(paymentId) {
        const { data } = await window.axios.post(
            `/customers/book/orders/${session.getOrderId}/payments/${paymentId}/bank-deposit`,
        );

        if (data) {
            await session.retrieve();
            return true;
        }
    }

    function cardDeclined() {
        try {
            window.axios.post(`/customers/book/${session.getOrderRef}/card-declined`);
        } catch {
            console.log('Error sending card declined event');
        }
    }

    const getCurrentService = computed(() => {
        return data.value.current_service ?? null;
    });

    const getCurrentPayment = computed(() => {
        return data.value.current_payment ?? null;
    });

    const getPaymentItemList = computed(() => {
        return data.value.payments ?? null;
    });

    const getPaymentTotal = computed(() => {
        return data.value.total ?? null;
    });

    const getPendingAmount = computed(() => {
        return data.value.pending_amount ?? null;
    });

    const hasFailedConfirmationPayment = computed(() => {
        return session.getJob?.failed_confirmation_payment_id ? true : false;
    });

    return {
        list,
        show,
        retrievePaymentSecret,
        retrySetup,
        flagBankDeposit,
        cardDeclined,

        // Getters
        isLoading: computed(() => data.value.loading),
        getCurrentService,
        getCurrentPayment,
        getPaymentItemList,
        getPaymentTotal,
        getPendingAmount,
        hasFailedConfirmationPayment,
    };
});
