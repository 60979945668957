<template>
    <div class="min-w-input rounded-input relative h-input w-full text-gray-600 focus-within:text-gray-400">
        <input
            v-model="internalSearch"
            type="search"
            name="q"
            class="peer h-input w-full rounded-muval-1 border border-muval-gray-4 bg-white py-2 pl-10 text-sm placeholder-[#999999] focus:border-focus focus:outline-none focus:ring-0"
            :placeholder="placeholder"
            autocomplete="off"
        />
        <span class="absolute inset-y-0 left-0 flex items-center border-none pl-2 text-[#999999] peer-focus:text-focus">
            <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.2708 11.0833H11.6453L11.4237 10.8695C12.2918 9.85808 12.7343 8.54963 12.6583 7.21889C12.5822 5.88814 11.9936 4.63862 11.0159 3.73265C10.0382 2.82667 8.74752 2.33472 7.41484 2.36008C6.08217 2.38544 4.81116 2.92614 3.86865 3.86865C2.92613 4.81116 2.38544 6.08218 2.36008 7.41485C2.33472 8.74752 2.82667 10.0382 3.73264 11.0159C4.63861 11.9936 5.88814 12.5822 7.21888 12.6583C8.54962 12.7343 9.85807 12.2918 10.8695 11.4237L11.0833 11.6454V12.2708L15.0416 16.2212L16.2212 15.0416L12.2708 11.0833ZM7.52077 11.0833C6.81617 11.0833 6.1274 10.8743 5.54155 10.4829C4.9557 10.0914 4.49908 9.53504 4.22945 8.88408C3.95981 8.23312 3.88926 7.51682 4.02672 6.82576C4.16418 6.1347 4.50347 5.49993 5.0017 5.0017C5.49992 4.50348 6.1347 4.16418 6.82576 4.02672C7.51681 3.88926 8.23311 3.95981 8.88408 4.22945C9.53504 4.49909 10.0914 4.9557 10.4829 5.54155C10.8743 6.1274 11.0833 6.81617 11.0833 7.52077C11.0839 7.98878 10.9922 8.45231 10.8134 8.88482C10.6345 9.31732 10.3722 9.7103 10.0412 10.0412C9.7103 10.3722 9.31732 10.6346 8.88482 10.8134C8.45231 10.9922 7.98878 11.0839 7.52077 11.0833V11.0833Z"
                    fill="currentColor"
                />
            </svg>
        </span>
        <div class="pointer-events-none absolute inset-0 flex h-full w-full items-center justify-end pr-4">
            <SvgIcon
                v-if="loading"
                name="system-loading"
                class="h-4 w-4 animate-spin text-muval-gray-4"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MuvalSearch',
    props: {
        label: {
            type: [String, null],
            default: null,
        },
        search: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'Search',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:search'],
    setup(props, { emit }) {
        const internalSearch = computed({
            get: () => {
                return props.search;
            },
            set: (value) => {
                emit('update:search', value);
            },
        });
        return {
            internalSearch,
        };
    },
};
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input[type='search'] {
    border: 1px solid gray;
    padding: 0.5em 2.5em;
    border-radius: 0.2em;
}

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    margin-right: -1rem;
    border-radius: 50em;
    background: url('data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M7.64052%207.1099C7.67536%207.14474%207.703%207.1861%207.72186%207.23162C7.74071%207.27714%207.75042%207.32594%207.75042%207.37521C7.75042%207.42448%207.74071%207.47327%207.72186%207.5188C7.703%207.56432%207.67536%207.60568%207.64052%207.64052C7.60568%207.67536%207.56432%207.703%207.5188%207.72186C7.47327%207.74071%207.42448%207.75042%207.37521%207.75042C7.32594%207.75042%207.27714%207.74071%207.23162%207.72186C7.1861%207.703%207.14474%207.67536%207.1099%207.64052L4.00021%204.53036L0.890521%207.64052C0.820156%207.71089%200.72472%207.75042%200.625208%207.75042C0.525697%207.75042%200.430261%207.71089%200.359896%207.64052C0.289531%207.57016%200.25%207.47472%200.25%207.37521C0.25%207.2757%200.289531%207.18026%200.359896%207.1099L3.47005%204.00021L0.359896%200.890521C0.289531%200.820156%200.25%200.72472%200.25%200.625208C0.25%200.525697%200.289531%200.430261%200.359896%200.359896C0.430261%200.289531%200.525697%200.25%200.625208%200.25C0.72472%200.25%200.820156%200.289531%200.890521%200.359896L4.00021%203.47005L7.1099%200.359896C7.18026%200.289531%207.2757%200.25%207.37521%200.25C7.47472%200.25%207.57016%200.289531%207.64052%200.359896C7.71089%200.430261%207.75042%200.525697%207.75042%200.625208C7.75042%200.72472%207.71089%200.820156%207.64052%200.890521L4.53036%204.00021L7.64052%207.1099Z%22%20fill%3D%22black%22/%3E%0A%3C/svg%3E')
        no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
}

input[type='search']::-webkit-search-cancel-button:hover {
    opacity: 0.5;
}

input[type='search']:focus::-webkit-search-cancel-button {
    opacity: 0.3;
    pointer-events: all;
}
</style>
