/**
 * Axios error post processing
 *
 * @package Muval Frontend SPA
 * @author Tim Maier
 */
import { notify } from '@kyvg/vue3-notification';

function handleErrorResult(error) {
    return {
        code: error?.response?.status,
        message: error?.response?.data?.message,
        errors: error?.response?.data?.errors,
    };
}

/**
 * Helper function to process Axios errors
 *
 * @param error Axios error object
 * @return object A consistent structured error object any SFC can use
 */
export function processError(error) {
    let result = {
        code: null,
        message: 'Unknown error',
        errors: null,
    };

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
            case 413:
                // Request Entity Too Large
                result = handleErrorResult(error);
                break;
            case 422:
                notify({
                    title: 'Oops there is an issue',
                    group: 'app_validation_error',
                    text: error.response.data.message,
                });
                result = handleErrorResult(error);
                break;
            case 400:
                notify({
                    title: null,
                    group: 'app_not_allowed',
                    text: error.response.data.message,
                });
                result = handleErrorResult(error);
                break;
            case 401:
                notify({
                    title: '401 Error Occurred',
                    group: 'app_error',
                    text: error.response.data.message,
                });
                result = handleErrorResult(error);
                break;
            case 403:
                notify({
                    title: '403 Error Occurred',
                    group: 'app_error',
                    text: error.response.data.message,
                });
                result = handleErrorResult(error);
                break;
            case 404:
                if (error.response.data.message == 'Terms and conditions not found.') {
                    break;
                } else {
                    notify({
                        title: 'Not found',
                        group: 'app_error',
                        text: error.response.data.message ? error.response.data.message : 'Resource not found',
                    });
                    result = handleErrorResult(error);
                    break;
                }
            case 500:
                notify({
                    title: '500 Error Occurred',
                    group: 'app_error',
                    data: {
                        url: error.config.url,
                        method: error.config.method,
                    },
                });
                result = handleErrorResult(error);
                break;
            case 503:
                result = handleErrorResult(error);
                result.message = error.message;
                break;

            default:
                result.message = 'Unknown error';
                break;
        }
    } else if (error.request) {
        // The request was made but no response was received
        notify({
            title: 'Muval API failed to respond',
            group: 'app_error',
            text: 'The Muval API might be down?',
        });
    } else {
        // Something happened in setting up the request that triggered an Error
        result.message = 'Axios error: ' + error.message;
    }

    return result;
}
