<template>
    <form
        class="flex flex-col gap-4"
        @submit="onSubmit"
    >
        <span class="font-header text-lg font-medium">Add required space</span>
        <MuvalInput
            label="Enter your required space"
            info-type="m&sup3;"
            vid="move.space"
        />
        <div class="ml-auto flex gap-4">
            <MuvalButton
                borderless
                tertiary
                @click.prevent="closeHandler"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                type="submit"
                primary
                alternate
            >
                Save & continue
            </MuvalButton>
        </div>
    </form>
</template>

<script setup>
import { closeInlineRequiredSpaceDialog, closeRequiredSpaceDialog, closeSpaceDialog } from '@/composables/dialog';
import { useSessionStore } from '@/store';
import { useForm } from 'vee-validate';

const props = defineProps({
    inline: {
        type: Boolean,
        default: false,
    },
});

const sessionStore = useSessionStore();
const router = useRouter();
const { handleSubmit } = useForm();

const closeHandler = () => {
    if (props.inline) {
        closeInlineRequiredSpaceDialog();
    } else {
        closeRequiredSpaceDialog();
    }
    closeSpaceDialog();
};

const onSubmit = handleSubmit(async (values) => {
    await window.axios.patch(`/customers/book/move/${sessionStore.getMoveId}/space/space-known`, {
        space: values.move.space,
    });

    if (sessionStore.hasReachedResults) {
        router.replace({ name: 'Loading' });
    } else {
        router.replace({ name: 'CustomerDetails' });
    }

    sessionStore.retrieve();

    closeHandler();
});
</script>
