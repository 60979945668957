<template>
    <MuvalModal
        v-model="open"
        :closeOnOutside="false"
        :rounded="true"
        title="Complete your payment via bank transfer"
        size="payment"
    >
        <div class="font-body">
            <div class="text-sm text-muval-gray-1">Your payment is needed to confirm your booking request.</div>
            <ol>
                <li class="relative flex flex-row gap-2 py-4 text-sm">
                    <!-- Connector Line -->
                    <div class="absolute h-full w-5">
                        <div class="relative flex h-full w-full">
                            <div class="absolute h-full w-full bg-white pb-2 pl-0 pt-7">
                                <div class="relative flex h-full w-full">
                                    <div
                                        class="border-s-1 border-grey-3 absolute left-1/2 h-full w-px border border-dashed"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Timeline Icon -->
                    <div class="relative flex h-6 w-6 items-center">
                        <span class="h-6 w-6 rounded-full bg-[#F7F7F7] text-center text-xs font-medium leading-6"
                            >1</span
                        >
                    </div>
                    <div>
                        Log into your internet banking account, and make a payment using the reference number below:
                        <table class="mt-2">
                            <tr>
                                <td class="p-1 px-2 pl-0 text-xs font-medium text-muval-gray-2">Bank</td>
                                <td class="p-1 px-2 text-xs font-medium">ANZ</td>
                            </tr>
                            <tr>
                                <td class="p-1 px-2 pl-0 text-xs font-medium text-muval-gray-2">BSB</td>
                                <td class="p-1 px-2 text-xs font-medium">014-274</td>
                            </tr>
                            <tr>
                                <td class="p-1 px-2 pl-0 text-xs font-medium text-muval-gray-2">Account number</td>
                                <td class="p-1 px-2 text-xxs font-medium">414979554</td>
                            </tr>
                            <tr>
                                <td class="p-1 px-2 pl-0 text-xs font-medium text-muval-gray-2">Account name</td>
                                <td class="p-1 px-2 text-xs font-medium">MUVAL PTY LTD</td>
                            </tr>
                            <tr>
                                <td class="p-1 px-2 pl-0 text-xs font-medium text-muval-gray-2">Amount</td>
                                <td class="p-1 px-2 text-xs font-medium">{{ paymentAmount }}</td>
                            </tr>
                            <tr>
                                <td class="p-1 px-2 pl-0 text-xs font-medium text-muval-gray-2">Message/reference</td>
                                <td class="p-1 px-2 text-xs font-medium">{{ session.getOrderRef }}</td>
                            </tr>
                        </table>
                    </div>
                </li>
                <li class="relative flex flex-row gap-2 py-2 text-sm">
                    <div class="relative flex h-6 w-6 items-center">
                        <span class="h-6 w-6 rounded-full bg-[#F7F7F7] text-center text-xs font-medium leading-6"
                            >2</span
                        >
                    </div>
                    <div>
                        Please email proof of your bank transfer (e.g. a payment receipt or screenshot) to
                        <a
                            :href="`mailto:finance@muval.com.au?subject=Bank%20transfer%20for%20order%20%23${session.getOrderRef}`"
                            target="_blank"
                            class="link"
                            >finance@muval.com.au</a
                        >.
                    </div>
                </li>
            </ol>
            <div class="py-1 pt-4 text-sm font-medium md:text-xs">Important information</div>
            <p class="text-xs text-muval-gray-2">
                Please note that we are unable to confirm your order and reserve the space for your booking until funds
                have been received in our account. This is usually the next business day when you transfer before 2pm
                AEST. However, times may vary between banks, especially when your transfer is made outside of business
                hours, or close to a weekend or public holiday. Please
                <a
                    class="link text-muval-gray-2"
                    @click="open = false"
                    >pay via card</a
                >
                if you require more immediate booking confirmation.
            </p>
        </div>

        <div class="items-top mt-5 flex gap-2 border-t-2 pt-5">
            <input
                id="terms-bank"
                v-model="termsBox"
                type="checkbox"
                class="mt-1 cursor-pointer rounded-muval-1 border-muval-gray-3 text-black focus:outline-none focus:ring-0"
            />
            <label
                for="terms-bank"
                class="text-xs text-muval-gray-2"
            >
                By proceeding you confirm that you have read and agree to Muval's
                <a
                    href="https://www.muval.com.au/privacy-policy"
                    class="link text-muval-gray-2"
                    target="_blank"
                    >Privacy Policy</a
                >
                and
                <a
                    href="https://www.muval.com.au/customer-terms-conditions"
                    class="link text-muval-gray-2"
                    target="_blank"
                    >Terms and Conditions</a
                >.
            </label>
        </div>
        <div class="flex flex-row items-center justify-between pt-6">
            <div class="flex flex-col">
                <span class="text-sm font-medium leading-4">
                    <span class="text-[#393939]">{{ paymentAmount }}</span>
                    <span class="pl-1 text-[#9B9B9B]">{{ paymentLabel }}</span>
                </span>
            </div>
            <MuvalButton
                type="submit"
                alternate
                :loading="reserveBankLoading"
                :disabled="!termsBox || reserveBankLoading"
                @click.prevent="handleReserveBankDeposit"
            >
                I have completed my bank transfer
            </MuvalButton>
        </div>
    </MuvalModal>
</template>

<script setup>
import MuvalModal from '@/components/MuvalModal.vue';
import { useSessionStore, usePaymentStore, useAppStore } from '@/store';

const app = useAppStore();
const session = useSessionStore();
const payment = usePaymentStore();

const props = defineProps({
    orderPaymentId: {
        type: [String, Number, null],
        default: null,
    },
    orderItemId: {
        type: [String, Number, null],
        required: false,
        default: null,
    },
    paymentAmount: {
        type: String,
        default: null,
        required: true,
    },
    paymentLabel: {
        type: String,
        required: false,
        default: '',
    },
});

const termsBox = ref(false);
const reserveBankLoading = ref(false);

const emit = defineEmits(['payment:success']);

const open = computed({
    get: () => app.getBankDepositModalOpen,
    set: (val) => {
        if (val) {
            app.openBankDepositModal();
        } else {
            app.closeBankDepositModal();
        }
    },
});

async function handleReserveBankDeposit() {
    if (reserveBankLoading.value) {
        return;
    }

    reserveBankLoading.value = true;

    try {
        if (props.orderPaymentId) {
            await payment.flagBankDeposit(props.orderPaymentId);
        } else {
            await session.pendingBankDeposit(props.orderItemId ? [{ id: props.orderItemId }] : null);

            emit('payment:success');
        }

        await app.closeBankDepositModal();
        await app.closePaymentModal();
    } catch (error) {
        console.log('error bank deposit', error);
    } finally {
        reserveBankLoading.value = false;
    }

    session.retrieve();
}
</script>
