import * as Sentry from '@sentry/vue';

function createSentry({ app, router }) {
    // only run Sentry in deployed environments
    if (
        import.meta.env.VITE_ENV == 'production' ||
        import.meta.env.VITE_ENV == 'testing' ||
        import.meta.env.VITE_ENV == 'development'
    ) {
        let reportingEnvironment = import.meta.env.VITE_RELEASE_BRANCH ?? import.meta.env.VITE_ENV;

        if (reportingEnvironment == 'main') {
            reportingEnvironment = 'production';
        }

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: reportingEnvironment,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration({
                    networkDetailAllowUrls: [
                        new RegExp(import.meta.env.VITE_API_BASE_URL + '/customers/book/orders/[A-Z0-9]+/inventory'),
                        new RegExp(import.meta.env.VITE_API_BASE_URL + '/customers/book/[A-Z0-9]+/trigger'),
                        new RegExp(import.meta.env.VITE_API_BASE_URL + '/customers/book/token'),
                        new RegExp(import.meta.env.VITE_API_BASE_URL + '/customers/book/[A-Z0-9+]'),
                    ],
                    networkRequestHeaders: ['X-Custom-Header'],
                    networkResponseHeaders: ['X-Custom-Header'],
                }),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.01,
            replaysOnErrorSampleRate: 1.0,
        });
    }
}

/*
 * Set the user context for Sentry
 * @param {string} orderRef - The order reference
 * @param {string} email - The email address
 * @returns {void}
 */
function setUser({ orderRef, email }) {
    if (
        import.meta.env.VITE_ENV == 'production' ||
        import.meta.env.VITE_ENV == 'testing' ||
        import.meta.env.VITE_ENV == 'development'
    ) {
        Sentry.setUser({
            id: orderRef,
            email: email,
        });
    }
}

export { createSentry, setUser };
