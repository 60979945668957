<template>
    <div
        v-if="!match.isMuvexpress"
        class="align-center my-auto flex flex-col flex-wrap justify-center gap-x-6 gap-y-2 md:flex-col lg:flex-col xl:flex-col 2xl:flex-row"
    >
        <MatchInfo
            date
            v-bind="{
                header: pickupDate,
                description: 'Pickup',
            }"
        />
        <MatchInfo
            date
            v-bind="{
                header: deliveryDate,
                description: 'Delivery',
            }"
        />
    </div>
</template>

<script setup lang="ts">
import type { MoveMatch, MoveJob } from '@/types';
import { useSessionStore } from '@/store';

const sessionStore = useSessionStore();

interface Props {
    match: MoveMatch;
    moveJob?: MoveJob;
}

const props = defineProps<Props>();

const pickupTime = computed(() => {
    if (sessionStore.hasMoveBeenAccepted && props.moveJob?.dates) {
        return props.moveJob?.dates?.pickup?.time?.formatted || '';
    }
    return props.match?.dates?.pickup?.time?.formatted || '';
});

const deliveryTime = computed(() => {
    if (sessionStore.hasMoveBeenAccepted && props.moveJob?.dates) {
        return props.moveJob?.dates?.delivery?.time?.formatted || '';
    }
    return props.match?.dates?.delivery?.time?.formatted || '';
});

const pickupDate = computed(() => {
    if (sessionStore.hasMoveBeenAccepted && props.moveJob?.dates) {
        return props.moveJob.dates.pickup.formatted + ' ' + pickupTime.value;
    }
    return props.match.dates.pickup.formatted;
});

const deliveryDate = computed(() => {
    if (sessionStore.hasMoveBeenAccepted && props.moveJob?.dates) {
        return props.moveJob.dates.delivery.formatted + ' ' + deliveryTime.value;
    }
    return props.match.dates.delivery.formatted;
});
</script>
