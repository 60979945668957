import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useSessionStore } from './session';
import Dinero from 'dinero.js';

export const useDiscountStore = defineStore('discount', () => {
    const session = useSessionStore();
    const getPrimaryItem = computed(() => {
        return session.getPrimaryItem;
    });

    // Return coupon if exists
    const getCoupon = computed(() => {
        if (getPrimaryItem.value && getPrimaryItem.value.coupon) {
            return getPrimaryItem.value.coupon;
        }
        return null;
    });

    // Return coupon if exists
    const getCouponCode = computed(() => {
        if (getPrimaryItem.value && getPrimaryItem.value.coupon) {
            return getPrimaryItem.value.coupon.code;
        }
        return null;
    });

    const hasCoupon = computed(() => {
        if (getPrimaryItem.value && getPrimaryItem.value.coupon) {
            return true;
        }
        return false;
    });

    // Return coupon if exists
    const getCouponAmountFormatted = computed(() => {
        if (getPrimaryItem.value && getPrimaryItem.value.coupon) {
            return getPrimaryItem.value.coupon.value.formatted;
        }
        return null;
    });

    const getCouponOrderItemId = computed(() => {
        if (getPrimaryItem.value && getPrimaryItem.value.coupon) {
            return getPrimaryItem.value.coupon.order_item_id;
        }
        return null;
    });

    const hasDiscount = computed(() => {
        if (session.order && session.order.items) {
            let total = 0;
            session.order.items.forEach((item) => {
                if (item.totals.DISCOUNT?.amount) {
                    total += Number.parseInt(item.totals.DISCOUNT.amount);
                }
            });

            // Format total from cents to dollars
            return total > 0;
        } else {
            return false;
        }
    });

    const getDiscountTotalAmount = computed(() => {
        if (session.order && session.order.items) {
            let total = 0;
            session.order.items.forEach((item) => {
                if (item.totals.DISCOUNT?.amount) {
                    total += Number.parseInt(item.totals.DISCOUNT.amount);
                }
            });

            // Format total from cents to dollars
            return Dinero({
                amount: total,
            }).toFormat('$0,0.00');
        } else {
            return 0;
        }
    });

    async function applyCoupon({ orderItemId, coupon }) {
        await window.axios.post(`/customers/book/coupon/${orderItemId}/apply`, {
            coupon: coupon,
        });
    }

    async function removeCoupon() {
        try {
            await window.axios.delete(`/customers/book/coupon/${getCouponOrderItemId.value}/remove`);
            await session.retrieve();
        } catch (error) {
            console.log('Error removing coupon', error);
        }
    }

    return {
        // Methods
        applyCoupon,
        removeCoupon,

        // Getters
        getCoupon,
        getCouponAmountFormatted,
        getCouponOrderItemId,
        getCouponCode,
        hasDiscount,
        getDiscountTotalAmount,
        hasCoupon,
    };
});
