<template>
    <div class="relative flex h-full w-full">
        <!-- Mask -->
        <TransitionGroup
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-x-90 opacity-0"
            enter-to-class="transform scale-x-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-x-100 opacity-100"
            leave-to-class="transform scale-x-90 opacity-0"
        >
            <div
                v-show="!arrivedState.right && canScroll"
                class="absolute right-0 h-full w-12 bg-gradient-to-l from-white to-transparent"
            ></div>
            <div
                v-show="!arrivedState.left && canScroll"
                class="absolute left-0 h-full w-12 bg-gradient-to-r from-white to-transparent"
            ></div>
        </TransitionGroup>

        <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-y-90 opacity-0"
            enter-to-class="transform scale-y-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-y-100 opacity-100"
            leave-to-class="transform scale-y-90 opacity-0"
        >
            <button
                v-if="!arrivedState.left && canScroll"
                class="absolute left-4 top-1/2 flex h-9 w-9 -translate-y-1/2 items-center justify-center rounded-full border bg-white"
                @click="scrollLeft"
            >
                <SvgIcon
                    class="h-4 w-4"
                    name="system-chevron-left"
                />
            </button>
        </Transition>
        <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-y-90 opacity-0"
            enter-to-class="transform scale-y-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-y-100 opacity-100"
            leave-to-class="transform scale-y-90 opacity-0"
        >
            <button
                v-if="!arrivedState.right && canScroll"
                class="absolute right-4 top-1/2 flex h-9 w-9 -translate-y-1/2 items-center justify-center rounded-full border bg-white"
                @click="scrollRight"
            >
                <SvgIcon
                    class="h-4 w-4"
                    name="system-chevron-right"
                />
            </button>
        </Transition>

        <div
            ref="scrollContainer"
            class="snap-x snap-mandatory overflow-x-auto overflow-y-hidden"
        >
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useScroll, useElementSize } from '@vueuse/core';

interface Props {
    cardWidth: number;
}

const { cardWidth } = defineProps<Props>();

const scrollContainer = ref<HTMLElement | null>(null);
const { width: containerWidth } = useElementSize(scrollContainer);
const { arrivedState, x } = useScroll(scrollContainer);
const canScroll = ref(false);

const updateCanScroll = () => {
    if (scrollContainer.value) {
        canScroll.value = scrollContainer.value.scrollWidth > scrollContainer.value.clientWidth;
    }
};

onMounted(updateCanScroll);
watch([x, containerWidth], updateCanScroll, { immediate: true });

const scrollRight = () => {
    if (!scrollContainer.value) return;
    const maxScrollLeft = scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
    if (scrollContainer.value.scrollLeft < maxScrollLeft) {
        scrollContainer.value.scrollBy({ left: cardWidth, behavior: 'smooth' });
    }
};

const scrollLeft = () => {
    if (!scrollContainer.value) return;
    if (scrollContainer.value.scrollLeft > 0) {
        scrollContainer.value.scrollBy({ left: -cardWidth, behavior: 'smooth' });
    }
};
</script>
