<template>
    <a
        class="relative flex cursor-pointer items-center text-sm font-normal text-muval-gray-1 underline underline-offset-2 outline-none ring-brand hover:text-black"
    >
        <slot />
        <SvgIcon
            name="system-chevron-right"
            class="h-4 w-4"
        />
    </a>
</template>
