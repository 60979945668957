<template>
    <div class="flex flex-col gap-4">
        <div class="flex gap-4">
            <div class="h-[93px] w-[93px] shrink-0 items-start rounded-md">
                <SvgIcon
                    class="h-full w-full"
                    name="mxtruck"
                />
            </div>

            <div class="flex max-w-[500px] flex-col items-start justify-between gap-1">
                <div class="flex items-center gap-2">
                    <span class="font-medium leading-none">MuvExpress</span>
                </div>

                <span class="text-left text-xs text-muval-gray-1">
                    Our SmartMatch technology finds the best available mover from our certified network. If you don't
                    like the mover we find, you can change at no extra cost
                </span>

                <div class="flex flex-wrap gap-2">
                    <div
                        v-if="!(session.isJobCancelled || session.isJobDeclined)"
                        class="inline-flex h-6 items-center gap-1 whitespace-nowrap rounded bg-muval-lime-2 px-1.5 text-muval-green-1"
                    >
                        <span class="text-xs font-medium">Recommended</span>
                    </div>
                    <div
                        v-if="match.isInterstate"
                        class="inline-flex h-6 items-center gap-1 whitespace-nowrap rounded bg-muval-gray-6 px-1.5"
                    >
                        <span class="text-xs font-medium">
                            {{ match.dates.delivery.formatted }}
                            <sup>*</sup>
                        </span>
                    </div>
                    <template
                        v-for="(item, idx) in items"
                        :key="idx"
                    >
                        <div
                            v-if="!(idx == 0 && session.getPickupDwelling == 'STORAGE')"
                            class="inline-flex h-6 items-center gap-1 whitespace-nowrap rounded bg-muval-gray-6 px-1.5"
                        >
                            <span class="text-xs font-medium">{{ item.text }}</span>
                        </div>
                    </template>
                </div>

                <span
                    v-if="session.getPickupDwelling == 'STORAGE'"
                    class="inline-flex items-center gap-1 whitespace-nowrap rounded-sm bg-[#F7F7F7] p-1"
                >
                    <div class="flex h-4 w-4 items-center justify-center rounded-full bg-white">
                        <SvgIcon
                            name="system-error"
                            class="h-3 w-3 text-muval-red-1"
                        />
                    </div>
                    <span class="text-xs">Muval Cover is not available for items coming out of storage</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { MoveMatch } from '@/types';
import { useSessionStore } from '@/store';

interface Props {
    match: MoveMatch;
}

defineProps<Props>();

const session = useSessionStore();

const items = [
    {
        icon: 'system-check',
        text: '$5,000 cover',
        color: 'text-muval-lime-1',
    },
    {
        icon: 'system-check',
        text: 'Quality assured',
        color: 'text-muval-lime-1',
    },
    {
        icon: 'system-star',
        text: '4+ guarantee',
        color: 'text-muval-amber',
    },
];
</script>
