import type { Ref } from 'vue';
export function useOrderToLetter(order: Ref<number>) {
    const letter = computed(() => {
        const asciiCode = 65 + (order.value % 26);

        return String.fromCharCode(asciiCode);
    });

    return { letter };
}
