<template>
    <form
        class="flex flex-col gap-4"
        @submit="onSubmit"
    >
        <span class="font-header text-lg font-medium">Edit pickup address</span>
        <MuvalAddress vid="move.pickup_address" />

        <div class="ml-auto flex gap-4">
            <MuvalButton
                borderless
                tertiary
                @click.prevent="closePickupAddressDialog"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                type="submit"
                primary
                alternate
                :disabled="isLoading"
                :loading="isLoading"
            >
                Save
            </MuvalButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useSessionStore } from '@/store';
import { closePickupAddressDialog } from '@/composables/dialog';

const sessionStore = useSessionStore();
const initialValues = ref({
    move: {
        pickup_address: sessionStore.getPrimaryProduct.pickup_address,
    },
});

const form = useForm({
    initialValues: initialValues.value,
});

const router = useRouter();
const isLoading = ref(false);
const onSubmit = form.handleSubmit(async (values) => {
    isLoading.value = true;
    await window.axios.patch(`/customers/book/move/${sessionStore.getMoveId}/pickup/details`, {
        ...values,
    });

    await sessionStore.retrieve();
    if (sessionStore.hasReachedResults) {
        router.replace({ name: 'Loading' });
    }

    closePickupAddressDialog();
    isLoading.value = false;
});
</script>
