<template>
    <TransitionRoot
        appear
        :show="modelValue"
        as="template"
    >
        <Dialog
            as="div"
            class="relative z-40"
            :initialFocus="initialFocus"
            @close="closeModal"
        >
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-opacity-75"
                    :class="devDebugClass ? devDebugClass : 'bg-black'"
                />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full w-auto justify-center text-center md:p-4"
                    :class="child ? 'items-end md:items-center' : 'items-end md:items-center'"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="scroll-shadows relative w-full transform rounded-t-2xl bg-white px-4 py-6 text-left align-middle transition-all md:rounded-lg md:px-5"
                            :class="[maxWidth, isTransparent]"
                        >
                            <!-- This is a focus hack so no input takes initial focus if it's within the slot -->
                            <a
                                ref="initialFocus"
                                class="hidden h-0 max-h-0"
                            ></a>

                            <slot />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';

const props = defineProps({
    modelValue: Boolean,
    maxWidth: {
        type: String,
        default: 'max-w-2xl',
    },
    devDebugClass: {
        type: [String, null],
        default: null,
    },
    disableEscapeClose: {
        type: Boolean,
        default: false,
    },
    transparent: {
        type: Boolean,
        default: false,
    },
});

const isTransparent = computed(() => {
    return props.transparent ? 'bg-opacity-0' : 'shadow-xl';
});

const emit = defineEmits(['update:modelValue']);
const closeModal = () => {
    emit('update:modelValue', false);
};

const handleKey = (event) => {
    if (event.key === 'Escape' && open) {
        event.preventDefault();
    }
};

watch(
    () => props.modelValue,
    (value) => {
        if (value && props.disableEscapeClose) {
            addEventListener('keydown', handleKey, false);
        } else {
            removeEventListener('keydown', handleKey, false);
        }
    },
    { immediate: true },
);

const initialFocus = ref(null);
</script>
