import { defineStore } from 'pinia';
import { useSessionStore } from '@/store';

export const usePetTransportStore = defineStore('pet_transport', () => {
    const sessionStore = useSessionStore();

    const exists = computed(() => !!getItem.value);

    const getItem = computed(() => {
        return sessionStore?.order?.items?.find((item) => item.productable_type === 'pet_transport') ?? null;
    });

    const getProduct = computed(() => {
        return getItem.value?.product ?? null;
    });

    const isCallbackRequested = computed(() => sessionStore.order?.pet_transport_callback_requested);

    const canRemove = computed(() => getItem.value?.status === 'UNCONFIRMED');

    const isConfirmed = computed(() => getItem.value?.status === 'CONFIRMED');

    const canIndividuallyBook = computed(() => {
        if (sessionStore.hasConfirmed) {
            return !isConfirmed.value;
        }

        return false;
    });

    const getPaidButtonCopy = computed(() => {
        if (isConfirmed.value && ['READY', 'COMPLETED'].includes(getProduct.value?.status)) {
            return 'Paid';
        }
        if (isConfirmed.value) {
            return 'Pending approval';
        }

        return null;
    });

    const hasFailedConfirmationPayment = computed(() => {
        if (getProduct.value?.confirmation_payment?.status == 'FAILED') {
            return true;
        }
        return false;
    });

    async function remove() {
        await window.axios.delete(`/customers/book/orders/${sessionStore.getOrderId}/pet-transport`);
        await sessionStore.retrieve();
    }

    return {
        exists,
        getItem,
        getProduct,
        isCallbackRequested,
        canRemove,
        isConfirmed,
        canIndividuallyBook,
        getPaidButtonCopy,
        hasFailedConfirmationPayment,
        remove,
    };
});
