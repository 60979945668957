<!-- todo At some point use for address dropdown to make searched terms bold in the select -->
<template>
    <div v-html="highlightedText" />
</template>

<script setup>
const props = defineProps({
    matchedSubStrings: {
        type: Array,
        default: () => [],
    },
    text: {
        type: String,
        required: false,
        default: null,
    },
});

const highlightedText = computed(() => {
    let text = props.text;

    let addedTextLength = 0;
    props.matchedSubStrings.forEach((term) => {
        const textLength = text.length;
        const start = term.offset + addedTextLength;
        const end = term.offset + term.length + addedTextLength;
        const highlightedTerm = text.slice(start, end);

        text = text.replace(highlightedTerm, `<strong>${highlightedTerm}</strong>`);
        addedTextLength = text.length - textLength;
    });
    return text;
});
</script>
