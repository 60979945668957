<template>
    <div class="relative flex flex-col">
        <header class="sticky z-50 flex items-center bg-white pb-6 text-lg font-medium">Inventory list</header>

        <div class="bg-white md:min-h-[750px]">
            <main>
                <div>
                    <MuvalInventory />
                </div>
            </main>
        </div>

        <footer
            class="w-ful sticky -bottom-6 z-50 -mb-6 flex flex-col items-center justify-center gap-4 bg-white md:gap-0 md:pt-0"
            :class="{ 'md:flex-row': session.hasConfirmed }"
        >
            <div
                v-if="showPotPlantAlert"
                class="w-full text-sm"
                :class="{ '-mb-2 pb-2 pt-4': !session.hasConfirmed }"
            >
                Remember to
                <a
                    href="https://interstatequarantine.org.au/travellers/"
                    target="_blank"
                    class="text-sm text-muval-sky-1 underline transition-colors hover:text-muval-blue-1"
                >
                    check the quarantine rules
                </a>
                before relocating your plants between states.
            </div>
            <div
                class="ml-auto flex h-full flex-col items-center justify-end gap-4 md:flex-row md:gap-2 md:py-4"
                :class="{ 'w-full': !showPotPlantAlert || !session.hasConfirmed, 'md:pt-0': showPotPlantAlert }"
            >
                <div
                    v-if="!session.hasConfirmed"
                    class="h-full w-full text-center md:text-left"
                >
                    Know your specific space requirement?
                    <a
                        class="cursor-pointer text-brand"
                        @click="openRequiredSpaceDialog"
                    >
                        Add m
                        <sup>3</sup>
                    </a>
                </div>

                <MuvalButton
                    class="w-full md:order-1 md:w-auto"
                    primary
                    alternate
                    :disabled="isSubmitting"
                    :loading="isSubmitting"
                    @click.prevent="onSubmit"
                >
                    Save inventory
                </MuvalButton>
                <MuvalButton
                    class="w-full md:w-auto"
                    borderless
                    tertiary
                    @click="closeSpaceDialog"
                >
                    Cancel
                </MuvalButton>
            </div>
        </footer>
    </div>
</template>

<script setup>
import { closeSpaceDialog, openRequiredSpaceDialog } from '@/composables/dialog';
import MuvalInventory from '@/components/inputs/inventory/MuvalInventory.vue';
import { useInventoryStore, useSessionStore } from '@/store';
import { toTypedSchema } from '@vee-validate/valibot';
import { pipe, number, object, maxValue, array } from 'valibot';
import { useForm } from 'vee-validate';
import { useRouter } from 'vue-router';

const showPotPlantAlert = computed(() => {
    return inventoryStore.showPotPlantAlert(values.move.inventory);
});

const session = useSessionStore();
const inventoryStore = useInventoryStore();

const validationSchema = toTypedSchema(
    object({
        move: object({
            inventory: array(
                object({
                    inventory_option_id: number(),
                    qty: pipe(number(), maxValue(100)),
                }),
            ),
        }),
    }),
);

const { isSubmitting, resetForm, handleSubmit, values } = useForm({
    validationSchema: validationSchema,
    initialValues: {
        move: {
            inventory: inventoryStore.getSelectedForForm,
        },
    },
    keepValuesOnUnmount: true,
});

const router = useRouter();
const onSubmit = handleSubmit(async (values) => {
    await inventoryStore.update(values.move.inventory);
    resetForm({
        values: {
            move: {
                inventory: inventoryStore.getSelectedForForm,
            },
        },
    });

    await session.retrieve();
    if (session.hasReachedResults && !session.hasConfirmed) {
        router.replace({ name: 'Loading' });
    }

    if (session.hasConfirmed) {
        router.replace({ name: 'Confirmed' });
    }

    closeSpaceDialog();
});

onMounted(async () => {
    await inventoryStore.init();
    const values = {
        move: {
            inventory: inventoryStore.getSelectedForForm,
        },
    };
    resetForm({
        values: values,
    });
});
</script>
