<template>
    <notifications
        group="auth"
        position="top"
    >
        <template #body="{ item, close }">
            <div class="m-2 flex h-16 w-64 flex-col rounded-md border-l-4 border-blue-600 bg-gray-200 p-2 shadow">
                <div class="flex justify-between">
                    <span>{{ item.title }}</span>

                    <button
                        class="flex rounded border border-gray-100 bg-gray-100 px-1 py-0.5 hover:bg-gray-200"
                        @click="close"
                    >
                        <svg
                            class="h-4 w-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Success Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_success"
        position="bottom center w-full"
        class="!bottom-[90px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-auto rounded-full border-gray-300 bg-white p-2 shadow">
                <div class="h-full w-2 rounded-full py-3"></div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-xs">
                        <span class="font-bold text-gray-900">{{ item.title }}</span>
                        <span class="font-light">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- App Error -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_error"
        position="bottom center w-full"
        class="!bottom-[90px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-auto rounded-full border-gray-300 bg-white px-4 py-2 shadow">
                <div class="h-full w-3 rounded-full py-3">
                    <div class="h-3 w-3 rounded-full bg-red-500"></div>
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-xs">
                        <span class="font-bold text-gray-900">
                            {{ item.title }}
                        </span>
                        <span class="font-light">{{ item.text }}</span>
                        <span>{{ item.data.info }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Form Info Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_form_info"
        position="bottom center w-full"
        class="!bottom-[90px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-auto rounded-full border-gray-300 bg-white px-4 py-2 shadow">
                <div class="flex h-auto w-3 items-center">
                    <div
                        class="h-3 w-3 rounded-full"
                        :class="item.data.type === 'error' ? 'bg-red-500' : 'bg-green-500'"
                    ></div>
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-xs">
                        <span class="font-bold text-gray-900">{{ item.title }}</span>
                        <span class="font-light">{{ item.text }}</span>
                        <span>{{ item.data.info }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Validation Error Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_validation_error"
        position="bottom center w-full"
        class="!bottom-[90px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-fit items-center rounded-full border-gray-300 bg-white px-4 py-2 shadow">
                <div class="h-full w-3 items-center rounded-full py-3">
                    <div class="h-3 w-3 rounded-full bg-red-500"></div>
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-xs">
                        <span class="font-bold text-gray-900">{{ item.title }}</span>
                        <span class="font-light">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- App not allowed -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="300"
        group="app_not_allowed"
        position="bottom center w-full"
        class="!bottom-[90px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex rounded-full border-gray-300 bg-white px-4 py-2 shadow">
                <div class="my-auto h-3 w-3 rounded-full">
                    <div class="h-3 w-3 rounded-full bg-yellow-500"></div>
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-xs">
                        <span class="font-bold text-gray-900">{{ item.title }}</span>
                        <span class="font-light">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Google Places Error Toast -->
    <notifications
        :max="1"
        width="300"
        group="google_places"
        position="bottom center"
        class="pointer-events-none"
    >
        <template #body="{ item }">
            <div class="pointer-events-none mb-20">
                <div class="relative flex h-12 rounded-full border-gray-300 bg-white px-4 py-2 shadow">
                    <div class="h-full w-3 rounded-full py-3">
                        <div class="h-3 w-3 rounded-full bg-blue-500"></div>
                    </div>
                    <div class="ml-4 flex w-full justify-between">
                        <div class="flex flex-col text-xs">
                            <span
                                v-if="item.title"
                                class="font-bold text-gray-900"
                            >
                                {{ item.title }}
                            </span>
                            <span class="font-light">{{ item.text }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </notifications>
</template>
