<template>
    <TransitionRoot
        as="template"
        :show="open"
    >
        <Dialog
            :unmount="false"
            as="div"
            class="fixed inset-0 overflow-hidden"
            @close="open = false"
        >
            <div class="absolute inset-0 overflow-hidden">
                <DialogOverlay class="absolute inset-0" />

                <div class="fixed inset-y-0 right-0 flex max-w-full md:max-w-md">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-200 transform-gpu"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-200 transform-gpu "
                        leave-from="translate-x-0"
                        leave-to="translate-x-full"
                    >
                        <div class="w-screen max-w-2xl">
                            <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-muval-1">
                                <div class="px-4 sm:px-6">
                                    <div class="flex items-start justify-between">
                                        <DialogTitle class="flex flex-col">
                                            <h3 class="text-lg font-normal text-gray-900">Your move</h3>
                                            <!-- <TimeEstimateSummary /> -->
                                        </DialogTitle>
                                        <div class="ml-3 flex h-7 items-center">
                                            <button
                                                data-testid="close-summary-dialog"
                                                type="button"
                                                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                                                @click="open = false"
                                            >
                                                <span class="sr-only">Close panel</span>
                                                <XMarkIcon
                                                    class="h-8 w-8"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="relative mt-6 flex-1">
                                    <div class="absolute inset-0 px-4">
                                        <FormSummary :showTitle="false" />

                                        <template v-if="session.hasCustomerDetails">
                                            <hr class="my-4" />
                                            <div class="flex flex-col gap-4">
                                                <span class="font-medium">Your details</span>
                                                <CustomerComponent />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { isSummaryDialogOpen } from '@/composables/dialog';
import { useSessionStore } from '@/store';

const session = useSessionStore();
const open = computed({
    get: () => {
        return isSummaryDialogOpen.value;
    },
    set: (value) => {
        isSummaryDialogOpen.value = value;
    },
});
</script>
