<template>
    <div
        v-if="selectOptions && selectOptions.length"
        class="relative flex w-full flex-col gap-4"
    >
        <div>
            <div
                class="pb-4 pt-2 font-header text-black"
                :class="inline ? 'text-base' : 'text-sm'"
            >
                Your place
            </div>
            <div class="flex flex-col gap-3">
                <div class="flex flex-col">
                    <div class="flex flex-wrap gap-2">
                        <div
                            v-for="tag in groupedOptions.place"
                            :key="tag.id"
                            class="flex"
                            :class="tag.hide == 1 ? 'hidden' : null"
                        >
                            <MuvalAccessTag
                                :selectedTags="fields"
                                :tag="tag"
                                :error="!!errorMessage"
                                @click="add(tag)"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="placeFieldsRequireInput"
                    class="mt-3"
                >
                    <template
                        v-for="field in placeFields"
                        :key="field.value.id"
                    >
                        <div
                            v-if="field.value.requires_input && isTagType(field, 'PLACE')"
                            class="flex items-center gap-2"
                        >
                            <MuvalInput
                                class="!h-[38px] max-w-sm"
                                :vid="`${vid}.${findIndexOfField(field.value.id)}.value`"
                                :label="field.value.label"
                                :infoType="field.value.unit"
                            />

                            <MuvalTip
                                v-if="field.value.tip"
                                :text="field.value.tip"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div>
            <div
                class="pb-4 pt-2 font-header text-black"
                :class="inline ? 'text-base' : 'text-sm'"
            >
                Your street
            </div>
            <div class="flex flex-col gap-3">
                <div class="flex flex-col">
                    <div class="flex flex-wrap gap-2">
                        <div
                            v-for="tag in groupedOptions.street"
                            :key="tag.id"
                            class="flex"
                            :class="tag.hide == 1 ? 'hidden' : null"
                        >
                            <MuvalAccessTag
                                :selectedTags="fields"
                                :tag="tag"
                                :error="!!errorMessage"
                                @click="add(tag)"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="streetFields.length"
                    class="mt-3"
                >
                    <template
                        v-for="field in streetFields"
                        :key="field.value.id"
                    >
                        <div
                            v-if="field.value.requires_input && isTagType(field, 'STREET')"
                            class="flex items-center gap-2"
                        >
                            <MuvalInput
                                class="!h-[38px] max-w-sm"
                                :vid="`${vid}.${findIndexOfField(field.value.id)}.value`"
                                :label="field.value.label"
                                :infoType="field.value.unit"
                            />

                            <MuvalTip
                                v-if="field.value.tip"
                                :text="field.value.tip"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="errorMessage"
        class="mt-3 text-sm text-red-500"
    >
        {{ errorMessage }}
    </div>
</template>

<script setup>
import { useFieldArray, useFieldError } from 'vee-validate';
import MuvalAccessTag from '@/components/inputs/tags/accessTag/MuvalAccessTag.vue';
import MuvalInput from '@/components/inputs/MuvalInput.vue';
import MuvalTip from '@/components/button/MuvalTip.vue';

const props = defineProps({
    vid: {
        type: String,
        required: true,
    },
    selectOptions: {
        type: Array,
        required: true,
    },
    inline: {
        type: Boolean,
        default: true,
    },
});

const { remove, push, fields } = useFieldArray(props.vid, undefined);
const errorMessage = useFieldError(props.vid);

const groupedOptions = computed(() => {
    let place = [];
    let street = [];

    props.selectOptions.forEach((option) => {
        if (option.type === 'PLACE') {
            place.push(option);
        } else {
            street.push(option);
        }
    });

    return {
        place,
        street,
    };
});

const placeFields = computed(() => {
    return fields.value.filter((field) => field?.value?.type === 'PLACE');
});

const streetFields = computed(() => {
    return fields.value.filter((field) => field?.value?.type === 'STREET');
});

function isTagType(field, type) {
    return field?.value?.type === type;
}

function isSelected(tagId) {
    const foundField = fields.value.find((field) => field?.value?.id === tagId);

    if (foundField) return true;
    return false;
}

const placeFieldsRequireInput = computed(() => {
    return placeFields.value.some((field) => field.value.requires_input);
});

function findIndexOfField(tagId) {
    return fields.value.findIndex((field) => field?.value?.id === tagId);
}

function add(tag) {
    if (!isSelected(tag.id)) {
        push({ ...tag, value: null });
        if (tag.related_items?.length) {
            handleRelatedTagAdd(tag);
        }
    } else {
        if (tag.related_items?.length && tag.related_items_state === 'SELECT') {
            handleRelatedTagRemove(tag);
        }
        remove(findIndexOfField(tag.id));
    }
}

function handleRelatedTagAdd(tag) {
    if (tag.related_items_state === 'SELECT') {
        tag.related_items.forEach((relatedItemId) => {
            if (!isSelected(relatedItemId)) {
                const relatedItem = props.selectOptions.find((option) => option.id === relatedItemId);
                if (relatedItem) {
                    push({
                        ...relatedItem,
                        value: null,
                    });
                }
            }
        });
    } else if (tag.related_items_state === 'DISABLE') {
        tag.related_items.forEach((relatedItemId) => {
            if (isSelected(relatedItemId)) {
                remove(findIndexOfField(relatedItemId));
            }
        });
    }
}

function handleRelatedTagRemove(tag) {
    tag.related_items.forEach((relatedItemId) => {
        // Check if no other tags want this item to stay
        const relatedTagsWantThis = fields.value.filter(
            (field) => field.value?.related_items?.includes(relatedItemId) && field.value.id !== tag.id,
        );
        if (!relatedTagsWantThis.length && isSelected(relatedItemId)) {
            remove(findIndexOfField(relatedItemId));
        }
    });
}
</script>
