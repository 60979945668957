import { defineStore } from 'pinia';
import { UAParser } from 'ua-parser-js';
import { useBroadcastChannel } from '@vueuse/core';
import { useRouter } from 'vue-router';
import useSlaask from '@/composables/useSlaask';

export const useAppStore = defineStore('app', () => {
    const router = useRouter();
    const hasReadParams = ref(false);
    const viewKey = ref(0);
    const ready = ref(false);
    const loading = ref(true);
    const paymentModalOpen = ref(false);
    const paymentModalOpenAsParent = ref(false);
    const bankDepositModalOpen = ref(false);
    const acceptInsuranceTermsModalOpen = ref(false);
    const darkMode = ref(false);
    const devMode = ref(false);
    const userAgent = ref(new UAParser());
    const predefined = ref({});
    const access_tags = ref([]);
    const direction = 'forward';
    const slaask = useSlaask();

    const { isSupported, post, data } = useBroadcastChannel({
        name: 'app-session',
    });

    // Show session expired if other tab is open
    watch(data, () => {
        if (data.value && isSupported.value && ready.value) {
            router.replace({ name: 'SessionExpired' });
        }
    });

    async function init() {
        // Post a message to other tabs
        if (isSupported.value) {
            post('init' + Math.random());
        }
        loading.value = true;

        if (!Object.values(predefined.value).length || !access_tags.value.length) {
            try {
                const [predefinedResponse, accessTagsResponse] = await Promise.all([
                    fetchPredefined(),
                    fetchAccessTags(),
                ]);
                predefined.value = predefinedResponse.predefined;
                access_tags.value = accessTagsResponse;
            } catch (error) {
                console.error(error);
            }
        }
        loading.value = false;
        ready.value = true;
    }

    async function fetchPredefined() {
        let { data: predefinedResponse } = await window.noAuthAxios.get('/customers/book/predefined');
        return predefinedResponse;
    }

    async function fetchAccessTags() {
        let {
            data: { data: accessTags },
        } = await window.noAuthAxios.get('/customers/book/access-tags');
        return accessTags;
    }

    // Toggle theme
    const toggleTheme = () => {
        darkMode.value = !darkMode.value;
    };

    const setLoading = () => {
        loading.value = true;
    };

    const completeLoading = () => {
        loading.value = false;
    };

    const openPaymentModal = (withBankDepositModel = false) => {
        paymentModalOpen.value = true;

        if (withBankDepositModel) {
            paymentModalOpenAsParent.value = true;
            bankDepositModalOpen.value = true;
        }
    };

    const closePaymentModal = () => {
        paymentModalOpen.value = false;
    };

    const getPaymentModalOpen = computed(() => paymentModalOpen.value);

    const openBankDepositModal = () => {
        bankDepositModalOpen.value = true;
    };

    const closeBankDepositModal = () => {
        bankDepositModalOpen.value = false;

        if (paymentModalOpenAsParent.value) {
            paymentModalOpenAsParent.value = false;
            paymentModalOpen.value = false;
        }
    };

    const getBankDepositModalOpen = computed(() => bankDepositModalOpen.value);

    const openAcceptInsuranceTermsModal = () => {
        acceptInsuranceTermsModalOpen.value = true;
    };

    const closeAcceptInsuranceTermsModal = () => {
        acceptInsuranceTermsModalOpen.value = false;
    };

    const isMobile = computed(() => userAgent.value.getDevice().type === 'mobile');

    const paymentSettings = computed(() => predefined.value?.payment_settings);

    const cardFeesEnabled = computed(() => paymentSettings.value?.card_fees_enabled);

    return {
        predefined,
        access_tags,
        viewKey,
        ready,
        loading,
        paymentModalOpen,
        acceptInsuranceTermsModalOpen,
        darkMode,
        devMode,
        userAgent,

        // Methods
        init,
        toggleTheme,
        setLoading,
        completeLoading,

        // Payment Modal
        openPaymentModal,
        closePaymentModal,

        // Bank Deposit Modal
        openBankDepositModal,
        closeBankDepositModal,

        // Accept Insurance Terms Modal
        openAcceptInsuranceTermsModal,
        closeAcceptInsuranceTermsModal,

        // Getters
        getPaymentModalOpen,
        getBankDepositModalOpen,
        isMobile,
        direction,
        hasReadParams,

        paymentSettings,
        cardFeesEnabled,

        // Slaask
        slaask,
    };
});
