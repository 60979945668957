<template>
    <div class="relative w-full">
        <DatePicker
            v-model="ghostDateModel"
            class="w-full max-w-sm rounded-2xl"
            :update-on-input="false"
            :input-debounce="1"
            color="muval"
            timezone="UTC"
            :minDate="minDate"
            :mode="mode"
        >
            <template #footer>
                <div class="flex w-full flex-col gap-2 px-4 pb-4 text-left">
                    <span>{{ timeOptionLabel }}</span>
                    <RadioGroup :defaultValue="time">
                        <RadioGroupLabel class="sr-only">Connection date time</RadioGroupLabel>
                        <div class="flex flex-wrap gap-2">
                            <RadioGroupOption
                                v-for="(item, key) in timeOptions"
                                :key="item.value"
                                v-slot="{ checked }"
                                as="div"
                                class="outline-none"
                                :value="item.value"
                                :data-cy="`select-${key}`"
                                @click="handleTimeChange(item.value)"
                            >
                                <div
                                    class="radio-small-content"
                                    :class="checked ? 'radio-checked' : 'radio-unchecked'"
                                >
                                    <span class="radio-small-label">{{ item.label }}</span>
                                </div>
                            </RadioGroupOption>
                        </div>
                    </RadioGroup>
                </div>
            </template>
        </DatePicker>
    </div>
</template>

<script setup>
import dayjs from 'dayjs';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import { useField } from 'vee-validate';

const props = defineProps({
    vid: {
        type: String,
        required: true,
    },
    mode: {
        type: String,
        default: 'single',
    },
    minDate: {
        type: Date,
        default: () => {
            // tomorrow
            const date = new Date();
            return date.setDate(date.getDate() + 1);
        },
    },
    timeOptionLabel: {
        type: String,
        default: 'Preferred time of day',
    },
    timeOptions: {
        type: Array,
        default: () => [
            { label: 'Morning', value: 'morning' },
            { label: 'Afternoon', value: 'afternoon' },
        ],
    },
});

const ghostDateModel = computed({
    get: () => {
        return dateValue.value;
    },
    set: (val) => {
        handleChange(val);
    },
});

const { value: dateValue, handleChange } = useField(toRef(props, 'vid'), undefined);

// Create a ref for the time value that defaults to morning or afternoon based on dateValue
const time = computed(() => {
    if (!dateValue.value) return 'morning';

    const date = dayjs.utc(dateValue.value);
    return date.hour() < 12 ? 'morning' : 'afternoon';
});

const handleTimeChange = (value) => {
    let date = dayjs.utc(dateValue.value); // Using UTC time, remove .utc() if you want local time

    if (value === 'morning') {
        date = date.hour(0).minute(0);
        time.value = 'morning';
    } else {
        date = date.hour(12).minute(0);
        time.value = 'afternoon';
    }

    handleChange(date.toDate()); // Converting the dayjs object back to a JavaScript Date object
};
</script>

<style>
.vc-muval {
    --vc-accent-50: #000;
    --vc-accent-100: #000;

    /* Range */
    --vc-accent-200: rgba(255, 92, 0, 0.2);

    --vc-accent-300: #ff5f1f;
    --vc-accent-400: #ff5f1f;
    --vc-accent-500: #ff5f1f;
    /* Active */
    --vc-accent-600: #ff5f1f;
    --vc-accent-700: #000;
    --vc-accent-800: #000;

    /* Date number color */
    --vc-accent-900: #000;
}
</style>
