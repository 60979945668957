//./plugins/posthog.js
import posthog from 'posthog-js';

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY;

export default {
    install(app) {
        if (!POSTHOG_API_KEY) {
            console.log('ℹ️ unable to load PostHog due to environment configuration');
            return;
        }

        app.config.globalProperties.$posthog = posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://app.posthog.com',
            autocapture: false, // disable autocapture to prevent huge amount of events for now
        });
    },
};
