import { defineStore } from 'pinia';
import { useSessionStore } from '@/store/session';
import { useRoute } from 'vue-router';

export const useFeedbackStore = defineStore('feedback', () => {
    const sessionStore = useSessionStore();
    const route = useRoute();

    if (!sessionStore?.orderRef) {
        throw new Error('⛔️ Too early to use feedback store');
    }

    const questions = computed(() => sessionStore.getOrder?.feedback);

    const pageNum = computed(() => {
        if (!questions.value) return 0;

        let result = 1;

        if (questions.value.slice(0, 1).every((q) => q.answered)) result = 2;
        if (questions.value.slice(1, 5).every((q) => q.answered)) result = 3;

        return result;
    });

    const canLeaveFeedback = computed(() => {
        return !!sessionStore.getJob?.past_delivery_date;
    });

    function getQuestions(indexes) {
        return indexes.map((index) => [...questions.value][index]);
    }

    function getQuestionsByRoute(_route) {
        if (!_route) _route = route.path;

        if (canLeaveFeedback.value) {
            switch (_route) {
                case '/feedback/removalist':
                    return getQuestions([0]);
                case '/feedback/removalist-additional':
                    return getQuestions([1, 2, 3, 4]);
                case '/feedback/muval':
                    return getQuestions([5]);
                default:
                    return [];
            }
        } else {
            return [];
        }
    }

    function getRouteDirection(to) {
        if (!canLeaveFeedback.value) {
            return '/';
        } else {
            if (to.path === '/feedback') {
                switch (pageNum.value) {
                    case 1:
                        return '/feedback/removalist';
                    case 2:
                        return '/feedback/removalist-additional';
                    case 3:
                        return '/feedback/muval';
                    case 4:
                        return '/feedback/thankyou';
                    default:
                        return '/results';
                }
            }
        }
    }

    const showReviewButtons = computed(() => {
        return !!(questions.value?.length && questions.value[5]?.rating >= 8);
    });

    return {
        questions,
        pageNum,
        canLeaveFeedback,
        showReviewButtons,
        getQuestionsByRoute,
        getRouteDirection,
    };
});
