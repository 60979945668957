<template>
    <div class="relative flex h-14">
        <div class="flex w-full justify-between">
            <SvgIcon
                class="w-32"
                name="muval_logo"
            />
            <div class="my-auto ml-auto flex justify-between gap-3">
                <MobileSlaaskButton />
                <a
                    href="tel:1300168825"
                    class="my-auto block rounded-full px-1 py-1.5 text-sm text-muval-gray-1 lg:hidden"
                    @click="analytics.phoneClicked()"
                >
                    <SvgIcon
                        class="h-5 w-5"
                        name="system-phone"
                    />
                </a>
                <Transition
                    enter-active-class="transition ease-out duration-500"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-400"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                    <MuvalButton
                        v-if="session.hasSession"
                        class="relative block !border-muval-gray-1 bg-white !text-muval-gray-1 lg:hidden"
                        secondary
                        type="button"
                        @click="openSummaryDialog"
                    >
                        <div class="relative flex">
                            <div class="absolute -right-[1.1rem] -top-2">
                                <span class="relative flex h-3 w-3">
                                    <span
                                        :class="form.loading ? 'animate-ping' : ''"
                                        class="absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"
                                    ></span>
                                    <span class="relative inline-flex h-3 w-3 rounded-full bg-brand"></span>
                                </span>
                            </div>
                            <div class="flex items-center gap-2">View booking</div>
                        </div>
                    </MuvalButton>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import { openSummaryDialog } from '@/composables/dialog';
import MuvalButton from './button/MuvalButton.vue';
import { useSessionStore, useFormStore, useAnalyticsStore } from '@/store';

const form = useFormStore();
const session = useSessionStore();
const analytics = useAnalyticsStore();
</script>
