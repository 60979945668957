<template>
    <div class="flex flex-col">
        <label
            class="flex gap-x-2 hover:cursor-pointer"
            :for="inputId"
        >
            <input
                :id="inputId"
                :data-cy="vid"
                class="mt-1"
                type="checkbox"
                :name="vid"
                :checked="checked"
                @input="handleChange"
            />
            <span>{{ label }}</span>
        </label>
        <div
            v-if="errorMessage"
            class="my=auto mx-2 flex flex-shrink-0 text-center"
        >
            <div class="my-auto inline-flex text-xs text-red-500">
                <span class="my-auto">{{ errorMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { idGen } from '@/utilities/idGen';

const inputId = idGen(6);

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: '',
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    value: {
        type: [Number, Boolean],
        default: true,
    },
    uncheckedValue: {
        type: [Number, Boolean],
        default: false,
    },
});

const { vid } = toRefs(props);

const { checked, errorMessage, handleChange } = useField(() => vid.value, undefined, {
    type: 'checkbox',
    checkedValue: props.value,
    uncheckedValue: props.uncheckedValue,
});
</script>
