import { ref, onMounted } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

export default function useGoogleAutocompleteService() {
    const autoCompleteService = ref(null);
    const placesService = ref(null);
    const error = ref(null);
    const hasError = ref(false);
    const loading = ref(false);
    const fetching = ref(false);
    const autoCompleteSessionToken = ref(null);

    async function initGoogle() {
        try {
            loading.value = true;

            const google = await new Loader({
                apiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
                libraries: ['places'],
                language: 'en',
            }).load();

            autoCompleteService.value = new google.maps.places.AutocompleteService();
            placesService.value = new google.maps.places.PlacesService(document.createElement('div'));

            // group requests together using a token to bill per session not per request
            autoCompleteSessionToken.value = new google.maps.places.AutocompleteSessionToken();

            error.value = null;
            hasError.value = false;
        } catch (e) {
            console.error('Failed to initialize Google Places API', e);
            error.value = e;
        } finally {
            loading.value = false;
        }
    }

    async function fetchResults(searchText, callback) {
        fetching.value = true;

        if (!autoCompleteService.value) {
            await initGoogle();
        }

        const options = {
            input: searchText,
            componentRestrictions: { country: ['au'] },
            sessionToken: autoCompleteSessionToken.value,
        };

        console.log('Fetch details request', options);

        autoCompleteService.value.getPlacePredictions(options, (predictions) => {
            console.log('Fetch details response', predictions);

            callback(predictions);
            fetching.value = false;
        });
    }

    async function fetchDetails(placeId) {
        const options = {
            placeId: placeId,
            fields: ['address_components', 'formatted_address', 'geometry'],
            sessionToken: autoCompleteSessionToken.value,
        };

        if (!placesService.value) {
            await initGoogle();
        }

        console.log('Place details request', options);

        placesService.value.getDetails(options, (place, status) => {
            console.log('Place details status', status);
            console.log('Place details response', place);
            console.log('Place details lat', place?.geometry?.location?.lat());
            console.log('Place details lng', place?.geometry?.location?.lng());
        });
    }

    onMounted(() => {
        initGoogle();
    });

    return {
        initGoogle,
        autoCompleteService,
        fetchResults,
        placesService,
        fetchDetails,
        error,
        hasError,
        loading,
        fetching,
    };
}
