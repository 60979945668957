<template>
    <div class="form-titled-item">
        <span class="text-xl font-medium">Select the items you want {{ sessionStore.getInsurancePolicyVerbPast }}</span>
        <p class="text-muval-gray-2">
            Please add the items you wish to {{ sessionStore.getInsurancePolicyVerbPresent }} and their corresponding
            value to the inventory list below to get your {{ sessionStore.getInsurancePolicyBrand }} quote.
        </p>
    </div>
    <div class="my-2 flex gap-5">
        <MuvalSearch
            v-model:search="searchString"
            class="w-full"
            placeholder="Search items"
        />
    </div>

    <template v-if="fuse">
        <div
            v-for="category in searchedInventoryOptions"
            :key="category.id"
            class="border-b"
        >
            <Disclosure v-slot="{ open }">
                <DisclosureButton
                    :disabled="searchString?.length"
                    class="sticky top-0 z-50 w-full bg-white"
                >
                    <div class="flex w-full items-center gap-4 px-2 py-3">
                        <SvgIcon
                            name="system-chevron-down"
                            :class="[open ? 'transform' : '-rotate-90', searchString?.length ? 'hidden' : '']"
                            class="h-6 w-6 text-muval-gray-1 duration-75"
                        />
                        <SvgIcon
                            class="h-6 w-6 text-muval-gray-1"
                            :name="`system-${getIcon(category.name)}`"
                        />
                        <div class="flex w-full items-baseline gap-4">
                            <span class="font-header">{{ category.name }}</span>
                            <div class="ml-auto pr-2 text-sm font-medium text-brand">
                                <span v-if="getCountByCategoryId(category.id) > 0">
                                    {{ getCountByCategoryId(category.id) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </DisclosureButton>
                <div
                    v-show="open || searchString?.length"
                    class="w-full"
                >
                    <DisclosurePanel static>
                        <div
                            class="mb-2 w-full space-y-2 overflow-y-hidden pl-4 md:max-h-64 md:overflow-y-auto md:pl-12"
                        >
                            <div
                                v-if="category.name.toLowerCase() == 'collectables'"
                                class="text-sm"
                            >
                                Please note: Antiques, fine art, oriental rugs, silverware and collectables cannot
                                exceed $5,000 on any individual item.
                            </div>
                            <div class="z-1 sticky top-0 flex w-full bg-white">
                                <div class="w-6/12"></div>
                                <div class="w-3/12 pr-3 text-end text-sm font-medium">Quantity</div>
                                <div class="w-3/12 pr-2 text-end text-sm font-medium">Total value</div>
                            </div>
                            <div
                                v-for="item in category.insurance_options"
                                :key="item.id"
                                class="flex w-full items-center pr-2"
                            >
                                <InsuranceOptionInput
                                    :item="item"
                                    class="w-6/12"
                                />
                            </div>
                        </div>
                    </DisclosurePanel>
                </div>
            </Disclosure>
        </div>
    </template>
</template>

<script setup>
import Fuse from 'fuse.js';
import { useInsuranceStore, useSessionStore } from '@/store';
import { useFieldArray } from 'vee-validate';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import MuvalSearch from '@/inputs/MuvalSearch.vue';
import { refDebounced } from '@vueuse/core';
import InsuranceOptionInput from './InsuranceOptionInput.vue';

const props = defineProps({
    searchInput: {
        type: String,
        required: true,
    },
});

const searchString = refDebounced(props.searchInput, 200);

const sessionStore = useSessionStore();
const insuranceStore = useInsuranceStore();
const fuse = new Fuse(insuranceStore.serverCategories, {
    keys: ['insurance_options.name'],
});

const searchedInventoryOptions = computed(() => {
    if (searchString.value) {
        return fuse.search(searchString.value).map((result) => result.item);
    } else {
        // Ensure only to return categories with an insurance_options array that has length
        return insuranceStore?.serverCategories?.filter((category) => category.insurance_options.length > 0);
    }
});

// Format icon name by category name
function getIcon(name) {
    switch (name.toLowerCase()) {
        case 'bedrooms':
            return 'bed';
        case 'dinner / lounge':
            return 'chair';
        case 'collectables':
            return 'art';
        case 'garage / shed':
            return 'toolbox';
        case 'other':
            return 'miscellaneous';
        default:
            return name.toLowerCase();
    }
}

const { fields } = useFieldArray('inventory');
const getCountByCategoryId = (categoryId) => {
    // Return 0 if there are no values
    if (!fields.value || fields.value.length === 0) {
        return 0;
    }

    // Find options for the given categoryId
    let categoryOptions = [];
    for (let category of searchedInventoryOptions.value) {
        if (category.id === categoryId) {
            categoryOptions = category.insurance_options;
            break;
        }
    }

    // If no options found for the category, return 0
    if (!categoryOptions.length) {
        return 0;
    }

    // Filter values that have their inventory_option_id in the categoryOptions
    return fields.value
        .filter((field) => categoryOptions.some((option) => option.id === field.value?.inventory_option_id))
        .reduce((total, field) => total + field.value.qty, 0);
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
