import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export function useEcho() {
    window.Pusher = Pusher;
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,

        // Don't add a host because we're using a cluster and this needs to be apart of the host
        ...((
            import.meta.env.VITE_ENV === 'local' ||
            import.meta.env.VITE_ENV === 'development' ||
            import.meta.env.VITE_ENV === 'testing'
        ) ?
            { wsHost: import.meta.env.VITE_PUSHER_HOST }
        :   {}),
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        wssPort: import.meta.env.VITE_PUSHER_PORT,
        cluster: import.meta.env.VITE_PUSHER_CLUSTER,
        forceTLS: import.meta.env.VITE_PUSHER_SCHEME === 'https',
        encrypted: import.meta.env.VITE_PUSHER_SCHEME === 'https',
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
    });
}
