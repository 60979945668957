<template>
    <div>
        <Tippy
            trigger="mouseenter click"
            animation="scale"
            placement="auto"
            @state="onStateChange"
        >
            <slot name="default">
                <div class="h-6 w-6 cursor-pointer rounded-full text-muval-gray-3 hover:bg-muval-gray-5">
                    <SvgIcon
                        class="h-6 w-6"
                        name="system-information"
                    />
                </div>
            </slot>
            <template #content>
                <slot name="content">
                    <div class="relative rounded-muval-1 border border-gray-200 bg-white px-4 py-3 shadow-muval-1">
                        <p class="font-body text-xs">{{ text }}</p>
                    </div>
                </slot>
            </template>
        </Tippy>

        <Teleport
            v-if="isOpen"
            to="body"
        >
            <Presence class="z-40">
                <Motion
                    :exit="{ opacity: 0 }"
                    :initial="{ opacity: 0 }"
                    :animate="{
                        opacity: 1,
                        transition: {
                            duration: 0.5,
                        },
                    }"
                    class="absolute right-0 top-0 w-full transition-all"
                >
                    <div class="pointer-events-none absolute inset-0 h-screen w-full overflow-clip bg-black/30"></div>
                </Motion>
            </Presence>
        </Teleport>
    </div>
</template>

<script setup>
import SvgIcon from '../utilities/SvgIcon.vue';
import { Motion, Presence } from '@oku-ui/motion';

defineProps({
    text: {
        type: String,
        default: '',
    },
});

const isOpen = ref(false);
const onStateChange = (state) => {
    isOpen.value = state?.isVisible;
};
</script>
