<template>
    <div
        v-if="match"
        class="flex items-center gap-3"
    >
        <!-- Company Ref -->
        <div
            v-if="match.isAnonymous"
            class="flex h-[34px] w-[34px] flex-shrink-0 items-center rounded bg-muval-gray-5 font-medium text-muval-gray-3"
        >
            <span
                v-if="match.isCustom || match.isTender"
                class="m-auto leading-none"
            >
                {{ letter }}
            </span>
            <span
                v-else
                class="m-auto leading-none"
            >
                {{ match.company_ref }}
            </span>
        </div>
        <div
            v-else-if="match.company"
            class="flex h-[34px] w-[34px] flex-shrink-0 items-center overflow-clip rounded-md"
        >
            <img
                :src="match.company.logo"
                class="object-contain"
            />
        </div>

        <!-- Review Component -->
        <CompanyModule
            v-bind="{
                company: match.company,
                match: match,
                readonly: readonly,
            }"
        />
    </div>
</template>

<script setup lang="ts">
import { useOrderToLetter } from '@/composables/orderLetter';
import type { MoveMatch } from '@/types';

interface Props {
    match: MoveMatch;
    readonly?: Boolean;
}

const props = defineProps<Props>();

const { match } = toRefs(props);

const order = computed(() => match.value.order);

const { letter } = useOrderToLetter(order);
</script>
