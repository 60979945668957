import { defineStore } from 'pinia';
import { useSessionStore } from '@/store/session';
import { useEcho } from '@/composables/useEcho';
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';
import { notify } from '@kyvg/vue3-notification';
import { type MoveMatch, type MoveMatchOption, type Company, ProductableType } from '@/types';

export const useResultsStore = defineStore('results', () => {
    // session is not typed yet so just return any
    const session = useSessionStore() as any;

    if (!session?.orderRef) {
        throw new Error('⛔️ Too early to use results store');
    }

    const router = useRouter();
    const queryClient = useQueryClient();

    // used to store the selected company for the dialog info view
    const selectedCompany = ref<Company | null>(null);

    // default sort is by order
    const matchSort = ref('order');

    const fetchMoveMatches = async (sort: string): Promise<MoveMatch[]> => {
        const sortParam = sort ? `sort=${sort}` : 'sort=order';
        const response = await window.axios.get(`/customers/book/${session.getMoveId}/matches?${sortParam}`);
        return response.data.data;
    };

    const sorts = ['order', 'lowest-price', 'highest-rating'];

    const lastFetchedMoveUpdatedAt = ref(null);

    /*
     * The prefetch function always refetches move matches but only resets the queries if the move's update
     * time has changed, indicating stale data, to optimize transitions when navigation and user experience.
     *
     * Use case: navigating from recommended to organic and back to recommended does not need to reset
     * the queries. As they shouldn't be stale.
     *
     * Use case: Switching the move details will reset the queries as the matches are assumed to be
     * different.
     *
     * Drawback: Selecting a match will also set the lastFetchedMoveUpdatedAt,
     * so navigating back to the results causes a visible refetch. Even though the "data" is not stale.
     * This could be optimized by using a more complex cache key provided by the backend that hashes all data
     * that has an impact on matching.
     */
    const prefetch = async () => {
        if (lastFetchedMoveUpdatedAt.value !== session.getMoveUpdatedAt) {
            sorts.forEach((sort) => {
                queryClient.resetQueries({
                    queryKey: ['move-matches', sort],
                });
            });
        }

        queryClient.fetchQuery<MoveMatch[]>({
            queryKey: ['move-matches', 'order'],
            queryFn: () => fetchMoveMatches('order'),
            staleTime: 0,
        });

        // set the cached last fetchedMoveUpdated at
        lastFetchedMoveUpdatedAt.value = session.getMoveUpdatedAt;
    };

    const isEnabled = computed(() => {
        return (
            router.currentRoute.value.name === 'Loading' ||
            router.currentRoute.value.name === 'Recommended' ||
            router.currentRoute.value.name === 'Organic'
        );
    });

    const { isPending, isError, isFetched, data, refetch } = useQuery<MoveMatch[]>({
        queryKey: ['move-matches', matchSort],
        queryFn: () => fetchMoveMatches(matchSort.value),
        enabled: isEnabled,
    });

    const hasMatches = computed(() => data && data.value && data.value.length > 0);

    const matches = computed(() => data && data.value);

    useEcho();

    window.Echo.channel(`MoveMatchingStart.${session.orderRef}`).listen('MoveMatchingStartEvent', () => {
        router.push({ path: '/results/loading' });
    });

    window.Echo.channel(`MoveMatchingDone.${session.orderRef}`).listen('MoveMatchingDoneEvent', async (e: any) => {
        await session.retrieve();
        refetch();

        if (router.currentRoute.value.path === '/results/review') {
            console.log('Skipping MoveMatchingDoneEvent: already on review page');
        } else {
            if (e.hasRecommendedMatches) {
                router.push({ path: '/results/recommended' });
            } else {
                router.push({ path: '/results/organic' });
            }
        }
    });

    window.Echo.channel(`MoveJobConfirmed.${session.orderRef}`).listen('MoveJobConfirmedEvent', async () => {
        await session.retrieve();

        router.push({ path: '/results/confirmed' });
    });

    const organicMatchList = computed(() =>
        matches?.value?.filter((match) => match.match_type !== 'MUVEXPRESS' && match.match_type !== 'SELF_PACK'),
    );

    const recommendedMatches = computed(() => matches?.value?.filter((match) => match.match_type !== 'ORGANIC'));

    const hasMuvexpressMatch = computed(() => matches?.value?.some((match) => match.match_type === 'MUVEXPRESS'));

    const runSelectMatch = async (match: MoveMatch | MoveMatchOption) => {
        await window.axios.patch(`/customers/book/${session.orderRef}`, {
            service_type: 'MOVE',
            form_step: router.currentRoute.value.path,
            move: {
                form_step: router.currentRoute.value.path,
                service_match_id: match.id,
            },
        });
    };

    const updateOptions = (matches: MoveMatch[], matchOptionId: string, resetAll = false) =>
        matches.map((match) => ({
            ...match,
            ...(match.options && {
                options: match.options.map((option) => ({
                    ...option,
                    selecting: resetAll ? false : option.id === matchOptionId,
                    disabled: resetAll ? false : option.id === matchOptionId,
                })),
            }),
        }));

    const updateMatch = (matches: MoveMatch[], matchId: string, resetAll = false) =>
        matches.map((match) => ({
            ...match,
            selecting: resetAll ? false : match.id === matchId,
            disabled: resetAll ? false : match.id === matchId,
        }));

    const updateMatchCallback = (matches: MoveMatch[], matchId: string) =>
        matches.map((match) => ({
            ...match,
            hasCallback: match.id === matchId ? true : match.hasCallback,
        }));

    const { mutate: selectMatch } = useMutation({
        mutationFn: runSelectMatch,
        onMutate: async (variables) => {
            const match = variables;
            await queryClient.cancelQueries({
                queryKey: ['move-matches', matchSort.value],
            });
            queryClient.setQueryData(['move-matches', matchSort.value], (old: MoveMatch[]) => {
                if (match.isOption) {
                    return updateOptions(old, match.id);
                } else {
                    return updateMatch(old, match.id);
                }
            });

            return { ...match };
        },
        onSuccess: async (result, variables, context) => {
            const match = variables;
            await session.retrieve();
            router.push({ path: '/results/review' });
            queryClient.setQueryData(['move-matches', matchSort.value], (old: MoveMatch[]) => {
                if (match.isOption) {
                    return updateOptions(old, context.id, true);
                } else {
                    return updateMatch(old, context.id, true);
                }
            });
        },
    });

    const runRequestCallback = async (match: MoveMatch) => {
        await window.axios.post(`/customers/book/orders/${session.getOrderId}/callback`, {
            productable_type: ProductableType.MOVE,
            match_id: match.id,
        });
    };

    const { mutate: requestCallback } = useMutation({
        mutationFn: runRequestCallback,
        onMutate: async (match) => {
            return { match };
        },
        onSuccess: async (result, variables, context) => {
            queryClient.setQueryData(['move-matches', matchSort.value], (old: MoveMatch[]) => {
                return updateMatchCallback(old, context.match.id);
            });
            notify({
                title: 'Callback requested',
                text: 'Thanks for requesting a callback. Please expect a call shortly.',
                group: 'app_form_info',
            });
        },
    });

    return {
        matches,
        hasMuvexpressMatch,
        matchSort,
        organicMatchList,
        recommendedMatches,
        hasMatches,
        selectMatch,
        prefetch,
        requestCallback,
        isError,
        isPending,
        isFetched,
        selectedCompany,
    };
});
