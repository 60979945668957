<template>
    <div class="w-full">
        <div class="mx-auto w-full md:max-w-md">
            <Disclosure v-slot="{ open }">
                <DisclosureButton
                    class="flex w-full justify-between gap-2 border-t px-3 py-6 text-left text-sm font-medium text-gray-400 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-brand focus-visible:ring-opacity-75 md:py-3"
                    :data-cy="`inventory-category-select-${category.name}`"
                    @click="setCategoryFilter(category.id)"
                >
                    <div class="flex gap-2">
                        <ChevronUpIcon
                            v-if="mode === 'accordion'"
                            :class="open || isOpen ? 'rotate-180' : 'rotate-90'"
                            class="h-5 w-5 text-muval-gray-1 transition"
                        />
                        <SvgIcon
                            class="my-auto h-5 w-5 transition"
                            :class="[
                                selected ? 'md:text-brand' : 'md:text-muval-gray-1',
                                open ? 'text-brand' : 'text-muval-gray-1',
                            ]"
                            :name="categoryIcon"
                        />
                        <span
                            class="font-medium transition"
                            :class="[
                                selected ? 'md:text-brand' : 'md:text-muval-gray-1',
                                open ? 'text-brand' : 'text-muval-gray-1',
                            ]"
                        >
                            {{ category.name == 'Custom' ? 'Custom items' : category.name }}
                        </span>
                    </div>

                    <!-- Counter -->
                    <div
                        v-if="categoryTotalCount > 0"
                        class="flex"
                        :class="[
                            selected ? 'md:text-brand' : 'md:text-muval-gray-1',
                            open ? 'text-brand' : 'text-muval-gray-1',
                        ]"
                    >
                        {{ categoryTotalCount }}
                    </div>
                </DisclosureButton>
                <template v-if="mode === 'accordion'">
                    <div v-if="open | isOpen">
                        <DisclosurePanel
                            static
                            :unmount="false"
                            class="mt-2 flex h-full w-full flex-col space-y-2 overflow-x-hidden px-2 pb-2 pl-4 text-sm text-gray-500"
                            :data-cy="`inventory-category-select-${category.name}`"
                        >
                            <template
                                v-for="item in options"
                                :key="item.id"
                            >
                                <InventoryOption
                                    :item="item"
                                    @click.stop
                                />
                            </template>
                        </DisclosurePanel>
                    </div>
                </template>
            </Disclosure>
        </div>
    </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/24/solid';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { useInventoryStore } from '@/store/inventory';
import InventoryOption from '@/components/inputs/inventory/InventoryOption.vue';
import { useFieldArray } from 'vee-validate';

const props = defineProps({
    category: {
        type: [Object, null],
        default: null,
    },
    mode: {
        type: String,
        default: 'accordion',
    },
    inline: {
        type: Boolean,
        default: false,
    },
    mobile: {
        type: Boolean,
        default: false,
    },
});

const inventory = useInventoryStore();
const selected = computed(() => inventory.filterCategory === props.category.id);

function setCategoryFilter(value = true) {
    // scroll to top smoothly only if not mobile
    if (!props.mobile) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    if (props.mode != 'accordion') {
        if (props.category.id === inventory.filterCategory) {
            // do nothing
        } else {
            inventory.setCategoryFilter(props.category.id, value);
        }
    } else {
        inventory.setCategoryFilter(props.category.id, value);
    }
}

const isOpen = computed(() => {
    if (
        inventory.searchTerm &&
        inventory.searchTerm.length &&
        props.category.move_options &&
        props.category.move_options.length &&
        props.category.name !== 'Custom'
    ) {
        return true;
    } else {
        return false;
    }
});

const { fields } = useFieldArray('move.inventory');

// Get total count of selected items for this category
const categoryTotalCount = computed(() => {
    if (fields.value) {
        return inventory.getCountByCategoryId(props.category.id, fields.value);
    }

    return 0;
});

const options = computed(() => {
    if (props.category && props.category.move_options) {
        return props.category.move_options;
    } else {
        return null;
    }
});

const categoryIcon = computed(() => {
    switch (props.category.slug) {
        case 'custom':
            return 'system-miscellaneous';
        case 'bedroom':
            return 'system-bed';
        case 'lounge':
            return 'system-chair';
        case 'kitchen':
            return 'system-kitchen';
        case 'dining':
            return 'system-dining';
        case 'laundry':
            return 'system-laundry';
        case 'office':
            return 'system-laptop';
        case 'hall':
            return 'system-picture';
        case 'garage_outside':
            return 'system-bike';
        case 'cartons_boxes':
            return 'system-box';
        case 'dinner_lounge':
            return '';
        case 'collectables':
            return '';
        case 'clothing':
            return 'system-clothing';
        default:
            return 'system-office';
    }
});
</script>

<style scoped>
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 6px;
}

.scrollbar-track-rounded-full::-webkit-scrollbar-track {
    border-radius: 6px;
}
.scrollbar-thumb-rounded-full::-webkit-scrollbar-track {
    border-radius: 6px;
}
.scrollbar-thumb-blue-50::-webkit-scrollbar-track {
    background: #c4c4c4;
}
.scrollbar-track-gray-50::-webkit-scrollbar-thumb {
    background: #6638b6;
}
</style>
