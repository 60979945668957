<template>
    <svg aria-hidden="true">
        <use
            :href="symbolId"
            :fill="color"
        />
    </svg>
</template>

<script setup lang="ts">
const props = defineProps({
    noPrefix: {
        type: Boolean,
        default: false,
    },
    prefix: {
        type: String,
        default: 'icon',
    },
    name: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        default: '#333',
    },
});

const symbolId = computed(() => {
    if (props.noPrefix) {
        return `#${props.name}`.toLowerCase();
    }
    return `#${props.prefix}-${props.name}`.toLowerCase();
});
</script>
