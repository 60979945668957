<template>
    <div class="relative mb-4 w-full">
        <textarea
            id="textarea"
            ref="textarea"
            :data-cy="vid"
            class="peer block min-h-[100px] w-full resize-y overflow-y-auto rounded-muval border border-gray-300 px-3 pt-3 placeholder-transparent focus:border-focus focus:outline-none focus:ring-0"
            :class="{
                'border-red-500': errorMessage,
                'bg-white': !errorMessage,
                'placeholder-gray-400': !label && placeholder,
            }"
            rows="1"
            :placeholder="placeholder ? placeholder : label"
            :value="inputValue"
            @keydown="assertMaxChar"
            @input="onUpdate"
            @change="handleChange"
            @blur="handleBlur"
        ></textarea>
        <div
            v-if="label"
            class="peer pointer-events-none absolute -top-2.5 left-2 z-20 line-clamp-1 text-sm text-gray-400 transition-all duration-75 peer-placeholder-shown:top-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-focus"
        >
            <span class="px-2">{{ label }}</span>
            <div class="absolute top-2.5 -z-10 h-0.5 w-full bg-white px-2"></div>
        </div>

        <div class="absolute -bottom-5 left-0 flex w-full whitespace-nowrap">
            <p
                v-if="!errorMessage"
                class="my-auto ml-2 text-sm text-gray-400"
            >
                {{ limitChar }} characters remaining
            </p>
            <div class="my-auto ml-auto flex">
                <a
                    v-if="inputValue && !errorMessage"
                    class="my-auto ml-auto cursor-pointer text-sm text-brand"
                    @click.prevent.stop="clearValue"
                >
                    Clear
                </a>
            </div>
        </div>

        <div
            v-if="errorMessage"
            class="absolute -bottom-5 left-0 flex w-full truncate whitespace-nowrap text-xxs"
            :class="{ 'text-red-500': errorMessage }"
        >
            <div class="ml-2 text-xxs">{{ errorMessage }}</div>
        </div>
    </div>
</template>

<script>
import { useField } from 'vee-validate';
import { useMagicKeys } from '@vueuse/core';
import { idGen } from '@/utilities/idGen';

export default {
    name: 'MuvalTextArea',
    props: {
        modelValue: {
            type: [String, null],
            required: false,
            default: null,
        },
        label: {
            type: [String, null],
            required: false,
            default: null,
        },
        placeholder: {
            type: [String, null],
            required: false,
            default: null,
        },
        vid: {
            type: String,
            required: false,
            default: () => idGen(6),
        },
        limit: {
            type: Number,
            required: false,
            default: 3000,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { value: inputValue, errorMessage, handleBlur, handleChange, meta } = useField(props.vid, undefined);

        const validationHover = ref(false);

        const limit = ref(props.limit);
        function assertMaxChar() {
            if (inputValue && inputValue.value && inputValue.value.length) {
                if (inputValue.value.length >= limit.value) {
                    inputValue.value = inputValue.value.substring(0, limit);
                }
            }
        }

        const limitChar = computed(() => {
            if (inputValue && inputValue.value && inputValue.value.length) {
                let currentChar = limit.value - inputValue.value.length;
                if (currentChar >= 0) {
                    return currentChar;
                } else {
                    return 0;
                }
            }
            return limit.value;
        });

        const undoValue = ref(null);
        function clearValue() {
            undoValue.value = inputValue.value;
            inputValue.value = '';
        }
        const keys = useMagicKeys();
        const shiftCtrlZ = keys['Ctrl+Z'];

        watch(shiftCtrlZ, (v) => {
            if (v) {
                inputValue.value = undoValue.value;
            }
        });

        watch(inputValue, (newVal) => {
            if (newVal === props.modelValue) {
                return;
            }
            // sync the model value with vee-validate model
            emit('update:modelValue', newVal);
        });

        // and you need to listen for `modelValue` prop changes
        watch(
            () => props.modelValue,
            (newModel) => {
                if (newModel === inputValue.value) {
                    return;
                }

                // Sync the vee-validate model with model value
                inputValue.value = newModel;
            },
        );

        onMounted(() => {
            let textarea = document.getElementById('textarea');
            if (textarea && textarea.style) {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        });

        function onUpdate(event) {
            handleChange(event);
            event.target.style.height = 'auto';
            event.target.style.height = `${event.target.scrollHeight}px`;
        }

        return {
            validationHover,
            clearValue,
            onUpdate,
            inputValue,
            handleChange,
            handleBlur,
            errorMessage,
            meta,
            limitChar,
            assertMaxChar,
        };
    },
};
</script>
