<template>
    <form class="flex flex-col gap-5">
        <span class="text-lg font-medium">Select a time to contact</span>
        <div class="m-auto flex w-full text-center">
            <MuvalDate
                vid="connection_callback_date"
                timeOptionLabel="Preferred time of day"
            />
        </div>
        <div class="ml-auto flex gap-4">
            <MuvalButton
                borderless
                tertiary
                @click.prevent="handleCancel"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                type="submit"
                primary
                alternate
                :loading="formStore.isLoading"
                :disabled="formStore.isLoading"
                @click.prevent="handleSubmit"
            >
                Save & continue
            </MuvalButton>
        </div>
    </form>
</template>

<script setup>
import { closeUtilitiesDialog } from '@/composables/dialog';
import { useFormStore, useSessionStore } from '@/store';
import { useForm } from 'vee-validate';

const formStore = useFormStore();
const sessionStore = useSessionStore();

const initialValues = ref({
    connection_callback_date: sessionStore.order.connection_callback_date,
});

/*eslint-disable */
const form = useForm({
    initialValues: initialValues.value,
});

const handleCancel = () => {
    formStore.currentForm.setFieldValue('connection_request_status', null);
    closeUtilitiesDialog();
};

const handleSubmit = async () => {
    await formStore.submit(form.values);
    closeUtilitiesDialog();
};
</script>
