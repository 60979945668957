import { defineStore } from 'pinia';
import { useSessionStore } from './session';

export const usePromotionStore = defineStore('promotion', () => {
    const sessionStore = useSessionStore();

    const promotions = computed(() => sessionStore?.order?.promotions);

    const getDoorDash = computed(() => {
        return promotions.value?.find((promotion) => promotion.promoter === 'DOOR_DASH');
    });

    return {
        promotions,
        getDoorDash,
    };
});
