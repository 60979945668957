<template>
    <div class="flex flex-col gap-3">
        <RadioGroup v-model="selectedPickupDateType">
            <RadioGroupLabel class="sr-only">Pickup date type</RadioGroupLabel>
            <div class="flex gap-2">
                <RadioGroupOption
                    v-for="(item, key) in selectOptions"
                    :key="item"
                    v-slot="{ checked }"
                    as="div"
                    :value="item.value"
                    :data-cy="`select-${key}`"
                    class="radio group"
                >
                    <div
                        class="radio-small-content"
                        :class="checked ? 'radio-checked' : 'radio-unchecked'"
                    >
                        <span class="radio-small-label">{{ item.label }}</span>
                    </div>
                </RadioGroupOption>
            </div>
        </RadioGroup>

        <template v-if="isUnsure">
            <RadioGroup v-model="selectedPickupDateInterval">
                <RadioGroupLabel class="sr-only">Pickup date interval</RadioGroupLabel>
                <div class="flex gap-2">
                    <RadioGroupOption
                        v-for="(item, key) in intervalSelectOptions"
                        :key="item"
                        v-slot="{ checked }"
                        as="div"
                        class="w-full outline-none"
                        :value="item.id"
                        :data-cy="`select-${key}`"
                    >
                        <div
                            class="radio-large-content"
                            :class="checked ? 'radio-checked' : 'radio-unchecked'"
                        >
                            <SvgIcon
                                class="m-auto h-14 w-14"
                                :name="item.icon"
                            />
                            <span class="radio-small-label-large">{{ item.label }}</span>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </template>

        <div
            v-if="!isUnsure"
            :key="pickupDateTypeValue"
        >
            <DatePicker
                v-model="dateModel"
                class="flex w-full"
                :modelConfig="modelConfig"
                :update-on-input="false"
                :input-debounce="1"
                color="muval"
                timezone="UTC"
                mode="range"
                :masks="{ weekdays: 'WW' }"
                :attributes="attributes"
                :is-range="isRange"
                :minDate="minDate"
                :first-day-of-week="2"
                :columns="smAndLarger ? 2 : 1"
                :isExpanded="smAndLarger ? true : false"
            >
                <template #header-title="{ title }">
                    <div class="font-body text-base font-medium text-muval-gray-1">
                        {{ title }}
                    </div>
                </template>
                <template #footer>
                    <template v-if="selectedPickupDateType == 'ON'">
                        <div class="flex w-full flex-wrap px-4 pb-4">
                            <RadioGroup v-model="selectedPickupDateFlexibility">
                                <RadioGroupLabel class="sr-only">Pickup date type</RadioGroupLabel>
                                <div class="flex flex-wrap gap-2">
                                    <RadioGroupOption
                                        v-for="(item, key) in selectOptionsFlexibility"
                                        :key="item"
                                        v-slot="{ checked }"
                                        as="div"
                                        class="radio group"
                                        :value="item.value"
                                        :data-cy="`select-${key}`"
                                    >
                                        <div
                                            class="radio-small-content"
                                            :class="checked ? 'radio-checked-black' : 'radio-unchecked'"
                                        >
                                            <span class="radio-small-label">{{ item.label }}</span>
                                        </div>
                                    </RadioGroupOption>
                                </div>
                            </RadioGroup>
                        </div>
                    </template>
                </template>
            </DatePicker>
            <div
                class="pointer-events-none absolute left-0 w-full truncate whitespace-nowrap text-xxs"
                :class="errorMessage ? 'text-red-500' : ''"
            >
                <span class="ml-2 text-xxs">{{ errorMessage }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import 'v-calendar/dist/style.css';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { DatePicker } from 'v-calendar';
import { useField, useFieldValue } from 'vee-validate';

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
});

const selectOptions = [
    { label: 'On', value: 'ON' },
    { label: 'Between', value: 'BETWEEN' },
    { label: 'I' + "'m" + '\n' + 'unsure', value: 'UNSURE' },
];

const selectOptionsFlexibility = [
    { label: 'Exact date', value: 'EXACT' },
    { label: '± 3 days', value: 'THREE_DAYS' },
    { label: '± a week', value: 'WEEK' },
    { label: '± a fortnight', value: 'FORTNIGHT' },
];

const dateModel = computed({
    get: () => {
        if (isRange.value) {
            return {
                start: pickupDateFrom.value.value,
                end: pickupDateBy.value.value,
            };
        } else {
            return pickupDateBy.value.value;
        }
    },
    set: (val) => {
        if (isRange.value) {
            pickupDateFrom.value.value = val.start;
            pickupDateBy.value.value = val.end;
        } else {
            pickupDateFrom.value.value = null;
            pickupDateBy.value.value = val;
        }
    },
});

const minDate = computed(() => {
    return new Date().setDate(new Date().getDate() + 3);
});
const breakpoints = useBreakpoints(breakpointsTailwind);
const smAndLarger = breakpoints.greater('sm');

const pickupDateFrom = useField('move.pickup_date_from');
const pickupDateBy = useField('move.pickup_date_by');

const pickupDateTypeValue = useFieldValue('move.pickup_date_type');

const attributes = computed(() => {
    return [
        {
            key: 'today',
            dates: new Date(),
            dot: true,
        },
    ];
});

const isRange = computed(() => {
    return pickupDateTypeValue.value == 'BETWEEN';
});

const isUnsure = computed(() => {
    return pickupDateTypeValue.value == 'UNSURE';
});

// Clear interval if type is not UNSURE
watch(isUnsure, (val) => {
    if (!val) {
        props.form.setFieldValue('move.pickup_date_interval', null);
    }
});

const modelConfig = {
    type: 'string',
    masks: {
        input: 'DD/MM/YYYY',
    },
    start: {
        masks: {
            input: 'DD/MM/YY',
            data: 'DD/MM/YY',
        },
    },
    end: {
        masks: {
            type: 'string',
            input: 'DD/MM/YY',
        },
    },
};

const intervalSelectOptions = ref([
    { label: 'In 3 months', id: 'THREE_MONTH', icon: 'system-three_month' },
    { label: 'In 6 months', id: 'SIX_MONTH', icon: 'system-six_month' },
    { label: 'In a year', id: 'YEAR', icon: 'system-year' },
]);

const errorMessage = computed(() => {
    return pickupDateFrom.value.errorMessage || pickupDateBy.value.errorMessage;
});

const [selectedPickupDateType] = props.form.defineField('move.pickup_date_type');

const [selectedPickupDateFlexibility] = props.form.defineField('move.pickup_date_flexibility');

const [selectedPickupDateInterval] = props.form.defineField('move.pickup_date_interval');

watch(selectedPickupDateType, (val) => {
    if (val == 'ON') {
        props.form.setFieldValue('move.pickup_date_flexibility', 'EXACT');
    }
    if (val == 'UNSURE') {
        props.form.setFieldValue('move.pickup_date_interval', 'THREE_MONTH');
    }
    if (val == 'BETWEEN') {
        props.form.setFieldValue('move.pickup_date_flexibility', null);
    }
});
</script>

<style>
.vc-muval {
    --vc-accent-50: #000;
    --vc-accent-100: #000;

    /* Range */
    --vc-accent-200: rgba(255, 92, 0, 0.2);

    --vc-accent-300: #ff5f1f;
    --vc-accent-400: #ff5f1f;
    --vc-accent-500: #ff5f1f;
    /* Active */
    --vc-accent-600: #ff5f1f;
    --vc-accent-700: #000;
    --vc-accent-800: #000;

    /* Date number color */
    --vc-accent-900: #000;
}
</style>
