<template>
    <div class="max-w-screen group absolute -bottom-1.5 h-1.5 w-full overflow-hidden">
        <div class="relative h-full w-full">
            <transition name="top-loader">
                <div
                    v-if="percentage"
                    class="absolute inset-0 z-10 h-full rounded-r-full"
                    :class="
                        formStore.loading ?
                            'animator bg-gradient-to-r from-brand via-muval-amber to-muval-brand'
                        :   'bg-brand'
                    "
                    :style="`width: ${percentage}%; transition: width 1s; }}`"
                ></div>
            </transition>
            <div
                class="-z-5 absolute inset-0 h-full rounded-r-full bg-brand"
                :style="`width: ${percentage}%; transition: width 1s;`"
            ></div>
        </div>
    </div>
</template>

<script setup>
import { useFormStore } from '@/store';

const formStore = useFormStore();

const routeToPercent = {
    '/step/pickup_details/pickup_address': 12.5,
    '/step/pickup_details/pickup_dwelling': 25,
    '/step/pickup_details/pickup_space': 37.5,
    '/step/pickup_details/pickup_access': 50,
    '/step/delivery_details/delivery_address': 62.5,
    '/step/delivery_details/delivery_access': 75,
    '/step/move_date': 87.5,
    '/step/utilities': 94,
    '/step/inventory': 96,
    '/step/customer_details': 100,
};

const router = useRouter();
const percentage = computed(() => {
    return routeToPercent[router.currentRoute.value.path];
});
</script>

<style scoped>
.animator {
    animation: indeterminateAnimation 1.5s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }
    40% {
        transform: translateX(0) scaleX(0.4);
    }
    100% {
        transform: translateX(0) scaleX(1);
    }
}

.top-loader-enter-active,
.top-loader-leave-active {
    transition: opacity 0.5s ease;
}

.top-loader-enter-from,
.top-loader-leave-to {
    opacity: 0;
}
</style>
