import { defineStore } from 'pinia';
import { useSessionStore } from './session';
import Dinero from 'dinero.js';
import posthog from 'posthog-js';

export const useAnalyticsStore = defineStore('analytics', () => {
    console.log('📊 analytics store loaded');

    const GTM_PARAMS = import.meta.env.VITE_GTM_PARAMS;

    // Add google tag script to head
    function addGoogleTagManager() {
        // skip loading script if GTM_PARAMS not found
        if (!GTM_PARAMS) {
            console.log('ℹ️ unable to load Google Tag Manager due to environment configuration');
            window.gtag = function () {};
            return;
        }

        // Initialize dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

        // Create script element to load gtm.js
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?${GTM_PARAMS}`;

        // Insert the script in the head of the document
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    }

    const session = useSessionStore();

    function event(eventName, eventParams = {}) {
        console.info(`event fired: ${eventName}`, eventParams);

        try {
            posthog.identify(session.getOrderRef);
            posthog.capture(eventName, { property: eventParams.value });
        } catch (e) {
            console.error('Post hog event capture error', e);
        }

        window.dataLayer?.push({
            event: eventName,
            ...eventParams,
        });
    }

    const sessionInitialized = computed(() => {
        return session.initialized;
    });

    const space = computed(() => {
        return session.getSpace;
    });

    const amount = computed(() => {
        return session.getItemsTotalAmount;
    });

    // Watch for space changes
    watch(space, (value) => {
        if (value && sessionInitialized.value) {
            submitSpace(value);
        }
    });

    function phoneClicked() {
        event('phone_clicked');
    }

    function bookStart() {
        event('book_start');
    }

    function bookLead() {
        event('book_lead', {
            value: space.value,
        });
    }

    function bookLeadWithEnergy() {
        event('book_lead_with_energy', {
            value: space.value,
        });
    }

    function bookConfirmed() {
        event('book_confirmed', {
            value: amount?.value ? Dinero({ amount: amount.value }).toFormat('0.00') : 0.0,
            currency: 'AUD',
        });
    }

    function bookConfirmedWithEnergy() {
        event('book_confirmed_with_energy', {
            value: amount?.value ? Dinero({ amount: amount.value }).toFormat('0.00') : 0.0,
            currency: 'AUD',
        });
    }

    function stepInventory() {
        event('step_inventory', {
            value: space.value,
        });
    }

    function chatStarted() {
        event('chat_started');
    }

    function paymentIntent() {
        event('payment_intent', {
            value: amount?.value ? Dinero({ amount: amount.value }).toFormat('0.00') : 0.0,
            currency: 'AUD',
        });
    }

    function submitSpace() {
        event('submit_space', {
            value: space.value,
        });
    }

    function directConnectCancelRemindMe() {
        event('direct_connect_cancel_remind_me');
    }

    function addressSearch(count) {
        event('address_search', {
            count: count,
        });
    }

    return {
        addGoogleTagManager,

        // comm triggers
        phoneClicked,
        chatStarted,

        bookStart,
        bookLead,
        bookLeadWithEnergy,
        bookConfirmed,
        bookConfirmedWithEnergy,

        submitSpace,
        stepInventory,
        paymentIntent,

        addressSearch,

        directConnectCancelRemindMe,
    };
});
