<template>
    <div
        v-if="isLocal"
        class="fixed bottom-3 right-3 z-[9999999] rounded bg-black px-4 text-3xl text-white"
    >
        <span class="sm:hidden">base</span>
        <span class="hidden sm:block md:hidden">sm</span>
        <span class="hidden md:block lg:hidden">md</span>
        <span class="hidden lg:block xl:hidden">lg</span>
        <span class="hidden xl:block 2xl:hidden">xl</span>
        <span class="3xl:hidden hidden 2xl:block">2xl</span>
        <span class="3xl:block 4xl:hidden hidden">3xl</span>
    </div>
</template>

<script setup>
const isLocal = computed(() => import.meta.env.VITE_ENV === 'local');
</script>
