/**
 * Axios Plugin implementing secured and plain axios requests
 */

import axios from 'axios';
import { processError } from '@/api/errorHandler';
import router from '../router';
import * as Sentry from '@sentry/vue';
import { useSessionStore } from '@/store/session';
import { setUser } from '@/plugins/sentry';
import axiosRetry from 'axios-retry';

const BASE_API_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        'X-Request-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Max-Age': '0',
        'Access-Control-Allow-Credentials': true,
    },
    withCredentials: true,
});

axiosRetry(axiosInstance, { retries: 3 });

const noAuthAxios = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        'X-Request-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});

axiosInstance.interceptors.request.use((config) => {
    const session = useSessionStore();
    if (session.bearer) {
        config.headers['Authorization'] = `Bearer ${session.bearer}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(null, async (error) => {
    if (
        import.meta.env.VITE_ENV == 'production' ||
        import.meta.env.VITE_ENV == 'testing' ||
        import.meta.env.VITE_ENV == 'development'
    ) {
        const session = useSessionStore();

        setUser({ orderRef: session.orderRef });

        Sentry.withScope((scope) => {
            const currentRoute = router?.currentRoute?.value;

            scope.setLevel(error?.response?.status == 422 ? 'warning' : 'error');

            scope.setExtras({
                error: error,
                code: error?.code,

                request_method: error?.config?.method,
                request_url: error?.config?.url,

                response_data: error?.response?.data,
                response_status: error?.response?.status,
                response_status_text: error?.response?.statusText,
                response_headers: error?.response?.headers,

                current_route_fullPath: currentRoute?.fullPath,
                current_route_name: currentRoute?.name,
                current_route_path: currentRoute?.path,
                current_route_query: currentRoute?.query,
                current_route_params: currentRoute?.params,
                current_route_hash: currentRoute?.hash,
            });

            Sentry.captureException(
                new Error(
                    'Axios Response on ' +
                        (currentRoute?.name ?? 'undefined route') +
                        ': ' +
                        error?.code +
                        ' (' +
                        error?.response?.status +
                        ' from ' +
                        error?.config?.method?.toUpperCase() +
                        ' ' +
                        error?.config?.url +
                        ')',
                ),
            );
        });
    }

    if (error?.response?.status === 503) {
        if (router.currentRoute.value.name !== 'Maintenance') {
            router.replace({ name: 'Maintenance' });
        }
    } else if (error?.code === 'ERR_NETWORK') {
        if (router.currentRoute.value.name !== 'ServerError') {
            router.push({ name: 'ServerError' });
        }
    } else if (error?.response?.status === 403) {
        if (router.currentRoute.value.name !== 'SessionForbidden') {
            router.replace({ name: 'SessionForbidden' });
        }
    } else if (error?.response?.status === 401) {
        if (router.currentRoute.value.name !== 'SessionExpired') {
            router.push({ name: 'SessionExpired' });
        }
    }

    let processedError = processError(error);

    console.log('Processed Error', processedError);

    return Promise.reject(processedError);
});

/*
 * Install global and Vue alias
 */
window.axios = axiosInstance; // Make instance available globally as axios()
window.noAuthAxios = noAuthAxios;

export default {
    install: (app /*options*/) => {
        app.config.globalProperties.$axios = axiosInstance; // Make instance available as instance property this.$axios()
    },
};
